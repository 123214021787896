let _customdo = {
    customer:{
        'name':$('#custom_do_customerName'),
        'address':$('#custom_do_customerAddress'),
        'siret':$('#custom_do_customerSiret'),
        'legal_status':$('#custom_do_customerLegalStatus'),
        'post_code':$('#custom_do_customerPostCode'),
        'city':$('#custom_do_customerCity'),
        'country':$('#custom_do_customerCountry'),
    },
    changeCustomer : function (data) {
        self = this;
        console.log(data);
        self.customer.name.val(data.name);
        self.customer.address.val(data.address);
        self.customer.siret.val(data.siret);
        self.customer.legal_status.val(data.legal_status);
        self.customer.post_code.val(data.post_code);
        self.customer.city.val(data.city);
        self.customer.country.val(data.country);
    },
    setData: function (data) {
    }
}
export {_customdo}
