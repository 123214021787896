import {French} from "flatpickr/dist/l10n/fr.js";

$(function () {
    "use strict";

    //global
    const spinner = $('<div style="position:relative"><div class="looping-rhombuses-spinner" style=""><div class="rhombus"></div><div class="rhombus"></div><div class="rhombus"></div></div></div>');
    const loader = $('\n' +
        '<div class="loading">\n' +
        '    <div class="block-loading"><div class=" sk-chase">\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '        <div class="sk-chase-dot"></div>\n' +
        '    </div></div>\n' +
        '</div>');

    const actionEvent = $('div.__action_event');

    /*
    * Scrollbar init important
    */
    $(".scrollbar").overlayScrollbars({
        scrollbars: {
            autoHide: "l",
            clickScrolling: !0
        },
        className: "os-theme-light"
    });

    $('.__hover_pulse').hover(function () {
        $(this).find('.animated').addClass('pulse');
    });

    // Show loader when submit form
    $('.__submit--l').on('click', function () {
        $('body').append(loader.clone());
    });

    /**
     * @type {jQuery|HTMLElement}
     * Convert text to lowercase
     */
    $('form').submit(function () {
        let formName = $(this).attr('name')

        if (formName.startsWith('user') || formName.startsWith('customer') || formName.startsWith('underwriter_')) {
            $('input[type=text]').val(function () {
                return this.value.charAt(0).toUpperCase() + this.value.substr(1).toLowerCase();
            });
        }
    });

    /**
     * @type {jQuery|HTMLElement}
     * Init Js Datepicker
     */
    let $jqDate = $('.js-datepicker');

    $jqDate.flatpickr({
        locale: French,
        dateFormat: 'd/m/Y',
        allowInput: true,
        enableTime: false,
        disableMobile: true,
        nextArrow: '<i class="zwicon-arrow-right" />',
        prevArrow: '<i class="zwicon-arrow-left" />'
    });

    if ($(".date-input-mask")[0]) {
        $(".date-input-mask").mask("00/00/0000", {
                placeholder: "__/__/____"
            }
        );
    }

    // TODO refacto this into underwriter product js
    // Underwriter Object
    let underwriter = {
        content: $('#__content-underwriter'),
        currentCover: $('.__currentCover'),
        currentCoverNever: $('.__currentCoverNever'),
        requiredOnCover: $('.__requiredOnCover'),
        requiredOnCoverTermination: $('.__requiredOnCoverTermination'),
        notAllowed: $('.__notAllowed'),
        riskInsuredLocated: $('.__risk-insured-located'),
        qualification: $('.__qualification'),
        blockQualification: $('.__block-qualification'),
        materialTrading: $('.__material-trading'),
        blockMaterialTrading: $('.__block-material-trading'),
        warnLastInsurerPremiumOk: $('#_warn-lastInsurerPremiumOk'),
        warnLegalRedress: $('#_warn-legalRedress'),
        warnTerminatedLossorMisrepresentation: $('#_warn-terminatedLossOrMisrepresentation'),
        warnActivityGeneralContracting: $('#_warn-activityGeneralContracting'),
        warnActivityIndividualHouseBuilder: $('#_warn-activityIndividualHouseBuilder'),
        warnNumberDisaster: $('#__warn-number-disaster'),
        blockNumberDisaster: $('.__block-number-sinister'),
        lastInsurerName: $('.__lastInsurerName'),
        lastInsurerNameOther: $('.__lastInsurerNameOther'),
        maxActivitiesVal: $('#__js_max_activities').val(),
        initToggleCurrentCoverNever: function (elem) {
            if (elem.val() !== 'jamais') {
                this.currentCoverNever.show();
                this.requiredOnCover.attr('required', 'required');
                this.requiredOnCoverTermination.attr('required', 'required');

                if (elem.val() === 'en_cours') {
                    this.requiredOnCoverTermination.removeAttr('required');
                }

                return;
            }
            this.currentCoverNever.hide();
            this.requiredOnCover.removeAttr('required');
            this.requiredOnCoverTermination.removeAttr('required');
        },
        currentCoverChange: function () {
            this.currentCover.on('change', function () {
                underwriter.initToggleCurrentCoverNever($(this))
            });
        },
        initToggleQualificationChange: function () {
            if (this.qualification.val() === '1') {
                this.blockQualification.removeClass('hide');
                return;
            }
            this.blockQualification.addClass('hide');
        },
        riskInsuredLocatedChange: function () {
            this.riskInsuredLocated.on('change', function () {
                if (this.value === '0') {
                    this.value = 1;
                    $('#__risk-insured-located').modal('show');
                }
            })
        },
        qualificationChange: function () {
            this.qualification.on('change', function () {
                if ($(this).val() === '1') {
                    underwriter.blockQualification.removeClass('hide');
                    return;
                }
                underwriter.blockQualification.addClass('hide');
            });
        },
        numberDisaster: function () {
            this.blockNumberDisaster.on('change', function () {
                if ($(this).val() > 3) {
                    underwriter.warnNumberDisaster.removeClass('hide');
                    this.value = 3;
                    $('#_number_disaster').modal('show');
                    return;
                }
                underwriter.warnNumberDisaster.addClass('hide');
            })
        },
        initToggleMaterialTradingChange: function () {
            if (this.materialTrading.val() === '1') {
                this.blockMaterialTrading.removeClass('hide');
                return;
            }
            this.blockMaterialTrading.addClass('hide');
        },
        materialTradingChange: function () {
            this.materialTrading.on('change', function () {
                if ($(this).val() === '1') {
                    underwriter.blockMaterialTrading.removeClass('hide');
                    return;
                }
                underwriter.blockMaterialTrading.addClass('hide');
            });
        },
        addLoader: function () {
            this.content.on('submit', 'form', function () {
                if ($(this).attr('id') !== 'customer_form') {
                    $('body').append(loader.clone());
                }
            });
        },
        lastInsurerNameChange: function () {
            this.lastInsurerName.on('change', function () {
                if ($(this).val() === 'AUTRE') {
                    $(this).closest('.col-md-12').removeClass('col-md-12').addClass('col-md-6');
                    underwriter.lastInsurerNameOther.attr('required', 'required').closest('.col-md-6').show();
                    return;
                }

                $(this).closest('.col-md-6').removeClass('col-md-6').addClass('col-md-12');
                underwriter.lastInsurerNameOther.removeAttr('required').closest('.col-md-6').hide();
            });
        },
    };

    /**
     * @type {jQuery|HTMLElement}
     * Add loader to underwriter form
     */
    underwriter.addLoader();

    // Init cover with input form (show or hide)
    underwriter.initToggleCurrentCoverNever(underwriter.currentCover);

    // Check select to show input form passive information
    underwriter.currentCoverChange();

    // init select to show qualification
    underwriter.initToggleQualificationChange();

    // Check risk insured located change
    underwriter.riskInsuredLocatedChange();

    // Check select to show qualification
    underwriter.qualificationChange();

    // Init select to show material trading information
    underwriter.initToggleMaterialTradingChange();

    // Check select to show material trading information
    underwriter.materialTradingChange();

    // If last insurer name is other, precise
    underwriter.lastInsurerNameChange();

    // Check number sinister
    underwriter.numberDisaster();

    /**
     * @type {jQuery|HTMLElement}
     * actions Overlay
     */
    actionEvent.on('click', '.__btnActionsTriggerOverlay', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).closest('td').find('.__boxActionsOverlay').toggleClass('__boxActionsOverlayShow');
        $('.__actionsOverlay').show();
    });
    $('.__actionsOverlay').on('click', function () {
        $('.__boxActionsOverlayShow').toggleClass('__boxActionsOverlayShow');
        $('.__btnActionsTriggerOverlay.active').removeClass('active');
        $('.__actionsOverlay').hide();
    });
    $('.__actionList').on('click', function () {
        $('.__actionsOverlay').trigger("click");
    });

    /**
     * @type {jQuery|HTMLElement}
     * AssignedTo for RCD history
     */
    actionEvent.on('change', 'select.update_assigned_to', function () {
        let that = $(this);
        let type = that.find(':selected').data('type');
        let url = "pending/change_assigned_to/" + type + "/" + $(this).closest('tr').data('value') + "/" + $(this).val();

        $.ajax({
            url: url,
            success: function (data) {
                $.notify(data.message, data.status);
            },
            error: function () {
                $.notify('Une erreur s\'est produite', 'danger');
            }
        });
    });

    /**
     * @type {jQuery|HTMLElement}
     * Ajax action trigger
     */
    actionEvent.on('click', 'span.__trigger', function () {
        let treatedActionsBlock = $(this).closest('td');
        let treatedRow = $(this).closest('tr');
        let entity = treatedRow.data('entity');
        let uuid = treatedRow.data('uuid');
        let type = treatedRow.data('type');
        let action = $(this).data('action');
        let spinnerCloned = spinner.clone();
        let currentUuid = window.location.pathname.split("/");
        currentUuid = currentUuid[currentUuid.length - 1];

        let url = '/' + type + '/' + action + '/' + uuid;

        //todo optimize for other products
        if (entity === 'batisolution') {
            url = '/' + entity + '/' + 'rcd' + '/' + action + '/' + uuid;
        }

        treatedActionsBlock.find('.__btnActionsTriggerOverlay').css('visibility', 'hidden');
        treatedActionsBlock.append(spinnerCloned);

        $.ajax({
            url: url,
            success: function (data) {
                switch (action) {
                    case 'pending':
                    case 'cancel-pending':
                    case 'delete':
                        treatedRow.fadeOut('slow', function () {
                            $(this).remove();
                        });

                        if ($('#__' + type).length > 0) {
                            $.get('/customers/show/' + currentUuid, function (data) {
                                $('#__' + type).replaceWith($('#__' + type, $(data)).first().addClass('active'));
                            });
                        }
                        break;
                }
                $.notify(data.message, data.status);
            },
            error: function () {
                treatedActionsBlock.find('.__btnActionsTriggerOverlay').css('visibility', 'visible');
                spinnerCloned.remove();
                $.notify('Une erreur s\'est produite', 'danger');
            }
        });
    });

    /**
     * AUTOCOMPLETE
     * Use it for autocomplete city and zip code
     */
    let inputCity = $('._city');
    let inputPostCode = $('._post-code');

    inputPostCode.autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "https://api-adresse.data.gouv.fr/search/?postcode=" + inputPostCode.val(),
                data: {q: request.term},
                dataType: "json",
                success: function (data) {
                    let postcodes = [];
                    response($.map(data.features, function (item) {
                        if ($.inArray(item.properties.postcode, postcodes) === -1) {
                            postcodes.push(item.properties.postcode);
                            return {
                                label: item.properties.postcode + " - " + item.properties.city,
                                city: item.properties.city,
                                value: item.properties.postcode
                            };
                        }
                    }));
                }
            });
        },
        select: function (event, ui) {
            inputCity.val(ui.item.city);
        }
    });

    inputCity.autocomplete({
        minLength: 3,
        source: function (request, response) {
            $.ajax({
                url: "https://api-adresse.data.gouv.fr/search/?city=" + inputCity.val(),
                data: {q: request.term},
                dataType: "json",
                success: function (data) {
                    let cities = [];
                    response($.map(data.features, function (item) {
                        if ($.inArray(item.properties.postcode, cities) === -1) {
                            cities.push(item.properties.postcode);
                            return {
                                label: item.properties.postcode + " - " + item.properties.city,
                                postcode: item.properties.postcode,
                                value: item.properties.city
                            };
                        }
                    }));
                }
            });
        },
        select: function (event, ui) {
            inputPostCode.val(ui.item.postcode);
        }
    });

    let formVoucher = $('form[name="voucher"] option:selected');
    let optionValues = [];

    formVoucher.each(function () {
        optionValues.push($(this).val());
    });

    if ($.inArray('0', optionValues) === -1) {
        $('.__contract').show();
        $('.__revival_voucher').hide();
    }

    $('[data-toggle="tooltip"]').tooltip();

    setTimeout(function () {
        $('.success').fadeOut();
    }, 2000);

    setTimeout(function () {
        $('.error').fadeOut();
    }, 4000);

    /**
     * AVATAR
     * Use preview when add avatar
     */
    let block = $(".__block-avatar");
    let inputFile = $("#user_avatarType_uploadFile");
    let btn = $("#__upload");
    let img = $("#avatar");

    block.on('click', '.__upload-file, .__upload-file-btn', function (e) {
        e.preventDefault();
        inputFile.click();
    });

    inputFile.on('change', function (e) {
        let i = 0;
        for (i; i < e.originalEvent.srcElement.files.length; i++) {
            let file = e.originalEvent.srcElement.files[i],
                reader = new FileReader();

            reader.onloadend = function () {
                img.attr('src', reader.result).animate({opacity: 1}, 700);
            };
            reader.readAsDataURL(file);
        }
    });

    let docProduct = $('#doc_product');
    let docType = $('#doc_type');

    docType.prop('disabled', true);

    docProduct.change(function () {
        docType.val('select_type');

        if (docProduct.val() !== 'select_product') {
            docType.prop('disabled', false);
        } else {
            docType.prop('disabled', true);
        }

        switch (docProduct.val()) {
            case '0':
                for (let i = 1; i < 12; i++) {
                    docType.find('option[value=' + i + ']').hide();
                }
                docType.find('option[value=12]').show();
                break;
            case '1':
                for (let i = 1; i < 12; i++) {
                    docType.find('option[value=' + i + ']').show();
                }
                docType.find('option[value=12]').hide();
                break;
        }
    });

    $('#user_cellPhone').change(function () {
        this.setAttribute('value', this.value);
    });

    $('#reset_password_email').change(function () {
        this.setAttribute('value', this.value);
    });

    //Avoid coma in search history amount
    let searchInput = $('.__search');
    searchInput.change(function () {
        let valueComa = this.value;

        if (valueComa.includes(',')) {
            let valueDot = valueComa.replace(',', '.');
            searchInput.val(valueDot);
        }
    });

    /**
     * SpringAssur form autoconnect
     */
    $('.__auto-connect-li').on('click', function () {
        $('form[name="autoConnectForm"]').submit();
    });

    /**
     * set active link nav on click
     */
    $('.__pool .nav .nav-item').on('click', function() {
        $(this).find('.nav-link').addClass('active');
        $(this).siblings('li').find('.nav-link').removeClass('active');
    });

    if (frames[0]) {
        frames[0].document.hidden = true;
        let cssLink = document.createElement("link");
        cssLink.href = "../build/fmelfinder/css/theme.min.css";
        cssLink.rel = "stylesheet";
        cssLink.type = "text/css";
        setTimeout(function () {
            frames[0].document.head.appendChild(cssLink);
            frames[0].document.hidden = false;
        }, 3000);
    }

    if ($('.switch-user').data('switch')) {
        /** switch user */
        $('#switch_user').select2({
            placeholder: 'Sélectionner un utilisateur',
            ajax: {
                url: Routing.generate('users_switch'),
                data: function (params) {
                    return {search: params.term};
                },
                processResults: function (data) {
                    return {
                        results: data
                    };
                }
            }
        });


        $('#switch_user').on('change', function() {
            if ($(this).val() !== '') {
                window.location.replace(window.location.href + '?_switch_user=' + $(this).val());
            }

            return false;
        });
    }
});