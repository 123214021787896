$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMbbRcdEasyPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMbbRcdEasySelectInsured             = $('#amendment_ax_mbb_rcd_easy_insured');
    let amendmentAxMbbRcdEasySelectHasContract         = $('#amendment_ax_mbb_rcd_easy_hasContract');
    let amendmentAxMbbRcdEasySelectRcproInsured        = $('#amendment_ax_mbb_rcd_easy_insuredRcpro');
    let amendmentAxMbbRcdEasyInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMbbRcdEasySelectInsuredLastTwoYears = $('#amendment_ax_mbb_rcd_easy_insuredLastTwoYears');
    let amendmentAxMbbRcdEasyInputNumberDisaster       = $('#amendment_ax_mbb_rcd_easy_numberDisaster');
    let amendmentAxMbbRcdEasyInputAmountDisaster       = $('#amendment_ax_mbb_rcd_easy_amountDisaster');
    let amendmentAxMbbRcdEasyDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMbbRcdEasyDateTermination           = $('#amendment_ax_mbb_rcd_easy_dateTermination');
    let amendmentAxMbbRcdEasySelectRcdFalseDeclaration = $('#amendment_ax_mbb_rcd_easy_declaration');
    let amendmentAxMbbRcdEasySelectUnpaid              = $('#amendment_ax_mbb_rcd_easy_unpaid');
    let amendmentAxMbbRcdEasySelectTerminateClaim      = $('#amendment_ax_mbb_rcd_easy_terminateClaim');
    let amendmentAxMbbRcdEasySelectResumptionLiability = $('#amendment_ax_mbb_rcd_easy_resumptionLiability');
    let amendmentAxMbbRcdEasySelectBoxOutsourcing      = $('#amendment_ax_mbb_rcd_easy_outsourcing');
    let amendmentAxMbbRcdEasySelectReceivership        = $('#amendment_ax_mbb_rcd_easy_receivership');
    let amendmentAxMbbRcdEasySelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMbbRcdEasySchedule                  = $('#amendment_ax_mbb_rcd_easy_schedule');
    let amendmentAxMbbRcdEasyAutoDebit                 = $('#amendment_ax_mbb_rcd_easy_autoDebit');

    function setFormHidden() {
        amendmentAxMbbRcdEasySelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasySelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasyDateTermination.val("");
        amendmentAxMbbRcdEasyInsuredLastTwoYears.hide();
        amendmentAxMbbRcdEasySelectInsuredLastTwoYears.val(1);
        amendmentAxMbbRcdEasyInputNumberDisaster.val(0);
        amendmentAxMbbRcdEasyInputAmountDisaster.val(0);
        amendmentAxMbbRcdEasySelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasySelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasySelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasySelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdEasyPassiveGroup.hide();
    }

    amendmentAxMbbRcdEasySelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdEasyPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMbbRcdEasySelectInsured.val()) === 1) {
        amendmentAxMbbRcdEasyPassiveGroup.show();
    }

    if (parseInt(amendmentAxMbbRcdEasySelectHasContract.val()) === 1) {
        amendmentAxMbbRcdEasyDateTerminationBlock.hide();
        amendmentAxMbbRcdEasyDateTermination.val("");
        amendmentAxMbbRcdEasyInsuredLastTwoYears.show();
    }

    amendmentAxMbbRcdEasySelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdEasyDateTerminationBlock.hide();
            amendmentAxMbbRcdEasyDateTermination.val("");
            amendmentAxMbbRcdEasyInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMbbRcdEasyInsuredLastTwoYears.hide();
        amendmentAxMbbRcdEasySelectInsuredLastTwoYears.val(0);
        amendmentAxMbbRcdEasyDateTerminationBlock.show();
    });

    amendmentAxMbbRcdEasySelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMbbRcdEasySelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdEasySelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdEasySchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMbbRcdEasyAutoDebit.val(1);
        }
    });

    amendmentAxMbbRcdEasyInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdEasySelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
            return false;
        }
    });

    amendmentAxMbbRcdEasySelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMbbRcdEasySelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdEasyInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdEasySelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMbbRcdEasySelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});