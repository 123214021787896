$(function () {
    "use strict";

    let underwriter = {
        inputHasBankLoan: $('#underwriter_dommage_hasBankLoan'),
        inputHasBankLoanRow: $('.__has-bank-loan-chosen'),
        hasBankLoanRows: $('.__has-bank-loan'),
        inputConstructionCost: $('#underwriter_dommage_constructionCost'),
        worktype: $('#underwriter_dommage_workType'),
        inputAmountExisting: $('#underwriter_dommage_amountExisting'),
        coastRisk: $('#underwriter_dommage_coastRisk'),
        horizontalRange: $('#underwriter_dommage_horizontalRange'),
        isProjectOwnerCustomer: $('#survey_isProjectOwnerCustomer'),
        slope: $('#underwriter_dommage_slope'),
        boxAmig: $('.__amig'),
        boxMic: $('.__mic'),
        boxDomi: $('.__domi'),
        boxSmalldo: $('.__smalldo'),

        initToggleBankInformation: function () {
            if (this.inputHasBankLoan.val() === '1') {
                this.inputHasBankLoanRow.css('display', 'block');
                this.hasBankLoanRows.each(function () {
                    $(this).attr('required', true)
                });

                return;
            }

            this.inputHasBankLoanRow.css('display', 'none');
        },
        toggleBankInformationAllowed: function () {
            this.inputHasBankLoan.on('change', function () {
                if (this.value === '1') {
                    underwriter.inputHasBankLoanRow.css('display', 'flex');
                    underwriter.hasBankLoanRows.attr('required', true);

                    return;
                }

                underwriter.inputHasBankLoanRow.css('display', 'none');
                underwriter.hasBankLoanRows.removeAttr('required');
                underwriter.hasBankLoanRows.each(function () {
                    this.value = '';
                }, this)
            })
        },
        statusConstructionCost: function () {
            let boxAmig = this.boxAmig;
            let boxDomi = this.boxDomi;
            this.inputConstructionCost.on('change', function () {
                if (boxAmig[0]) {
                    if (this.value > 7000000) {
                        $('#__coast-risk-amig').modal();
                        this.value = 0;
                    }
                }
                else if (boxDomi[0]) {
                    if (this.value > 250000) {
                        $('#__coast-risk-domi').modal();
                        this.value = 0;
                    }
                } else if (boxSmalldo[0]) {
                    if (this.value > 500000) {
                        $('#__coast-risk-smalldo').modal();
                        this.value = 0;
                    }
                } else {
                    if (this.value > 5000000) {
                        $('#__coast-risk').modal();
                        this.value = 0;
                    }
                }
            })
        },
        amountExisting: function () {
            if (this.boxAmig[0] || this.boxMic[0]) {
                this.inputAmountExisting.on('change', function () {
                    if (this.value > 250000) {
                        $('#__amount-existing').modal();
                        this.value = 0;
                    }
                });
            }
        },
        initStatusWorktype: function () {
            if (underwriter.worktype.val() > 0) {
                underwriter.inputAmountExisting.prop('readonly', false);
            }
        },
        statusWorktype: function () {
            this.worktype.on('change', function () {
                if (this.value > 0) {
                    underwriter.inputAmountExisting.prop('readonly', false);
                    return;
                }

                underwriter.inputAmountExisting.val(0);
                underwriter.inputAmountExisting.prop('readonly', true);
            })
        },
        statusCoastRisk: function () {
            this.coastRisk.on('change', function () {
                if (this.value > 0) {
                    $('#_subscription-prohibited').modal();
                    underwriter.coastRisk.val(0);
                }
            });
        },
        statusSlope: function () {
            this.slope.on('change', function () {
                if (this.value > 2) {
                    $('#_subscription-prohibited').modal();
                    underwriter.slope.val(0);
                }
            });
        },
        statusHorizontalRange: function () {
            this.horizontalRange.on('change', function () {
                if (this.value > 0) {
                    $('#_subscription-prohibited').modal();
                    underwriter.horizontalRange.val(0);
                }
            });
        },
        ownerCustomerChange: function () {
            self = this;
            self.showSubscriber(this.isProjectOwnerCustomer.val());
            this.isProjectOwnerCustomer.on('change', function () {
                self.showSubscriber(this.value);
            });
        },
        showSubscriber(isProjectOwnerCustomer){
            if (isProjectOwnerCustomer > 0) {
                $('#subscriberLastName').hide();
                $('#subscriberFirstName').hide();
                $('#subscriberPhone').hide();
                $('#subscriberEmail').hide();
            } else {
                $('#subscriberLastName').show();
                $('#subscriberFirstName').show();
                $('#subscriberPhone').show();
                $('#subscriberEmail').show();
            }
        }
    };

    underwriter.initToggleBankInformation();
    underwriter.toggleBankInformationAllowed();
    underwriter.statusConstructionCost();
    underwriter.initStatusWorktype();
    underwriter.statusWorktype();
    underwriter.statusCoastRisk();
    underwriter.statusSlope();
    underwriter.statusHorizontalRange();
    underwriter.amountExisting();
    underwriter.ownerCustomerChange();
});
