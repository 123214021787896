$(function () {
    "use strict";

    //Pack Choices (Pne)
    $(".box-pack label").click(function () {
        $(".box-pack label").each(function () {
            $(this).closest('div').removeClass('active');
        });
        $(this).closest('div').toggleClass('active');
    });

    /**
     *
     * @type {jQuery|HTMLElement}
     *
     * customer have not a contract, display dateTermination
     */
    let passiveGroup = $('._underwriter-lu-insured');
    let selectInsured = $('#underwriter_pne_insured');
    let selectHasContract = $('#underwriter_pne_hasContract');
    let selectFalseDeclaration = $('#underwriter_lu_pne_declaration');

    function setFormHidden() {
        selectHasContract.val(0).attr('selected', 'selected');
        selectFalseDeclaration.val(0).attr('selected', 'selected');
        passiveGroup.hide();
    }

    if (parseInt(selectInsured.val()) === 1) {
        passiveGroup.show();
    } else {
        setFormHidden();
    }

    selectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            passiveGroup.show();
            return;
        }
        setFormHidden();
    });

    /**
     * @type {jQuery|HTMLElement}
     * Get the total when changing a building size on the field totalbuildingsize
     */
    let homeSize = $('#underwriter_pne_homeBuildingSize');
    let homeEmptySize = $('#underwriter_pne_homeBuildingEmptySize');
    let shopSize = $('#underwriter_pne_shopBuildingSize');
    let officeSize = $('#underwriter_pne_officeBuildingSize');
    let garageSize = $('#underwriter_pne_garageBuildingSize');
    let nightClubSize = $('#underwriter_pne_nightClubBuildingSize');
    let totalSize = $('#underwriter_pne_totalBuildingsSize');
    $([homeSize, homeEmptySize, shopSize, officeSize, garageSize, nightClubSize]).each(function () {
        $(this).change(function () {
            totalSize.val(
                parseFloat(homeSize.val()) +
                parseFloat(homeEmptySize.val()) +
                parseFloat(shopSize.val()) +
                parseFloat(officeSize.val()) +
                parseFloat(garageSize.val()));
        });
    });

    /**
     * @type {{selectDeclaration: (*|jQuery|HTMLElement), selectTerminateClaim: (*|jQuery|HTMLElement), statusSelectDeclaration: statusSelectDeclaration}}
     */
    let underwriter = {
        selectDeclaration: $('#underwriter_pne_declaration'),
        selectTerminateClaim: $('#underwriter_pne_terminateClaim'),
        selectSchedule: $('#underwriter_pne_schedule'),
        selectAutoDebit: $('#underwriter_pne_autoDebit'),
        inputShopBuildingSize: $('#underwriter_pne_shopBuildingSize'),
        statusSelectDeclaration: function () {
            this.selectDeclaration.on('change', function () {
                if (parseInt(underwriter.selectDeclaration.val()) === 1) {
                    $('#_subscription-prohibited').modal();
                    this.value = 0;
                }
            })
        },
        statusSelectTerminateClaim: function () {
            this.selectTerminateClaim.on('change', function () {
                if (parseInt(underwriter.selectTerminateClaim.val()) === 1) {
                    $('#_subscription-prohibited').modal();
                    this.value = 0;
                }
            })
        },
        statusSelectSchedule: function () {
            this.selectSchedule.on('change', function () {
                if (parseInt(underwriter.selectSchedule.val()) === 4) {
                    $('#__schedule-pne').modal();
                    underwriter.selectAutoDebit.val(1);
                }
            });
            this.selectAutoDebit.on('change', function () {
                if ((parseInt(this.value) === 0) && (parseInt(underwriter.selectSchedule.val()) === 4)) {
                    $('#__schedule-pne').modal();
                    underwriter.selectAutoDebit.val(1);
                }
            })
        },
        statusInputShopBuildingSize: function () {
            this.inputShopBuildingSize.on('change', function () {
                if (Number.isNaN(this.value) || this.value === '') {
                    this.value = 0;
                    return;
                }

                if (parseFloat(this.value)) {
                    $('#__building-size-pne').modal();
                }
            })
        }
    };

    underwriter.statusSelectDeclaration();
    underwriter.statusSelectTerminateClaim();
    underwriter.statusSelectSchedule();
    underwriter.statusInputShopBuildingSize();
});