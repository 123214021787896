$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentLsMicRcdConstructorPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentLsMicRcdConstructorSelectRcdInsured          = $('#amendment_ls_mic_rcd_constructor_insured');
    let amendmentLsMicRcdConstructorSelectHasContract         = $('#amendment_ls_mic_rcd_constructor_hasContract');
    let amendmentLsMicRcdConstructorSelectRcproInsured        = $('#amendment_ls_mic_rcd_constructor_insuredRcpro');
    let amendmentLsMicRcdConstructorInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentLsMicRcdConstructorSelectInsuredLastTwoYears = $('#amendment_ls_mic_rcd_constructor_insuredLastTwoYears');
    let amendmentLsMicRcdConstructorInputNumberDisaster       = $('#amendment_ls_mic_rcd_constructor_numberDisaster');
    let amendmentLsMicRcdConstructorInputAmountDisaster       = $('#amendment_ls_mic_rcd_constructor_amountDisaster');
    let amendmentLsMicRcdConstructorDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentLsMicRcdConstructorDateTermination           = $('#amendment_ls_mic_rcd_constructor_dateTermination');
    let amendmentLsMicRcdConstructorSelectRcdFalseDeclaration = $('#amendment_ls_mic_rcd_constructor_declaration');
    let amendmentLsMicRcdConstructorSelectUnpaid              = $('#amendment_ls_mic_rcd_constructor_unpaid');
    let amendmentLsMicRcdConstructorSelectTerminateClaim      = $('#amendment_ls_mic_rcd_constructor_terminateClaim');
    let amendmentLsMicRcdConstructorSelectResumptionLiability = $('#amendment_ls_mic_rcd_constructor_resumptionLiability');
    let amendmentLsMicRcdConstructorSelectBoxOutsourcing      = $('#amendment_ls_mic_rcd_constructor_outsourcing');
    let amendmentLsMicRcdConstructorSelectReceivership        = $('#amendment_ls_mic_rcd_constructor_receivership');
    let amendmentLsMicRcdConstructorSelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentLsMicRcdConstructorSchedule                  = $('#amendment_ls_mic_rcd_constructor_schedule');
    let amendmentLsMicRcdConstructorAutoDebit                 = $('#amendment_ls_mic_rcd_constructor_autoDebit');

    function setFormHidden() {
        amendmentLsMicRcdConstructorSelectHasContract.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorSelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorDateTermination.val("");
        amendmentLsMicRcdConstructorInsuredLastTwoYears.hide();
        amendmentLsMicRcdConstructorSelectInsuredLastTwoYears.val(1);
        amendmentLsMicRcdConstructorInputNumberDisaster.val(0);
        amendmentLsMicRcdConstructorInputAmountDisaster.val(0);
        amendmentLsMicRcdConstructorSelectUnpaid.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorSelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorSelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentLsMicRcdConstructorPassiveGroup.hide();
    }

    amendmentLsMicRcdConstructorSelectRcdInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentLsMicRcdConstructorPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentLsMicRcdConstructorSelectRcdInsured.val()) === 1) {
        amendmentLsMicRcdConstructorPassiveGroup.show();
    }

    if (parseInt(amendmentLsMicRcdConstructorSelectHasContract.val()) === 1) {
        amendmentLsMicRcdConstructorDateTerminationBlock.hide();
        amendmentLsMicRcdConstructorDateTermination.val("");
        amendmentLsMicRcdConstructorInsuredLastTwoYears.show();
    }

    amendmentLsMicRcdConstructorSelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentLsMicRcdConstructorDateTerminationBlock.hide();
            amendmentLsMicRcdConstructorDateTermination.val("");
            amendmentLsMicRcdConstructorInsuredLastTwoYears.show();
            return;
        }
        amendmentLsMicRcdConstructorInsuredLastTwoYears.hide();
        amendmentLsMicRcdConstructorSelectInsuredLastTwoYears.val(0);
        amendmentLsMicRcdConstructorDateTerminationBlock.show();
    });

    amendmentLsMicRcdConstructorSelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentLsMicRcdConstructorSelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorSelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorSchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentLsMicRcdConstructorAutoDebit.val(1);
        }
    });

    amendmentLsMicRcdConstructorInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorSelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorSelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentLsMicRcdConstructorSelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentLsMicRcdConstructorSelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentLsMicRcdConstructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});