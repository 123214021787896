$(document).ready(function () {
    let underwriterAssurGarage =  {
        siretCreating: $('#underwriter_assur_garage_siretCreating'),
        siret: $('#underwriter_assur_garage_siret'),
        annexActivity: $('#underwriter_assur_garage_annexActivity'),
        gasBottleSale: $('#underwriter_assur_garage_gasBottleSale'),
        gasBottleSalePercent: $('#underwriter_assur_garage_gasBottleSalePercent'),
        fuelDistribution: $('#underwriter_assur_garage_fuelDistribution'),
        fuelDistributionPercent: $('#underwriter_assur_garage_fuelDistributionPercent'),
        theWoodcraft: $('#underwriter_assur_garage_theWoodcraft'),
        theWoodcraftPercent: $('#underwriter_assur_garage_theWoodcraftPercent'),
        plasticMaterials: $('#underwriter_assur_garage_plasticMaterials'),
        plasticMaterialsPercent: $('#underwriter_assur_garage_plasticMaterialsPercent'),
        threeLastYearInsured: $('#underwriter_assur_garage_threeLastYearInsured'),
        lastInsurerName: $('#underwriter_assur_garage_lastInsurerName'),
        numberContract: $('#underwriter_assur_garage_numberContract'),
        effectiveAt: $('#underwriter_assur_garage_effectiveAt'),
        echeanceAt: $('#underwriter_assur_garage_echeanceAt'),
        terminationAt: $('#underwriter_assur_garage_terminationAt'),
        threeLastYearDisaster: $('#underwriter_assur_garage_threeLastYearDisaster'),
        numberDisaster: $('#underwriter_assur_garage_numberDisaster'),
        amountDisaster: $('#underwriter_assur_garage_amountDisaster'),
        statusSiretCreating: function () {
            // Siret
            switch (underwriterAssurGarage.siretCreating.val()) {
                case '0':
                    underwriterAssurGarage.siret.attr('disabled', false);
                    underwriterAssurGarage.siret.attr('required', true);
                    break;
                case '1':
                    underwriterAssurGarage.siret.attr('disabled', true);
                    underwriterAssurGarage.siret.attr('required', false);
                    underwriterAssurGarage.siret.val(null);
                    break;
            }
        },
        statusAnnexActivity: function () {
            switch (underwriterAssurGarage.annexActivity.val()) {
                case '0':
                    underwriterAssurGarage.gasBottleSale.val(0);
                    underwriterAssurGarage.gasBottleSale.attr('readonly', true);
                    underwriterAssurGarage.gasBottleSalePercent.val(null);
                    underwriterAssurGarage.gasBottleSalePercent.attr('disabled', true);

                    underwriterAssurGarage.fuelDistribution.val(0);
                    underwriterAssurGarage.fuelDistribution.attr('readonly', true);
                    underwriterAssurGarage.fuelDistributionPercent.val(null);
                    underwriterAssurGarage.fuelDistributionPercent.attr('disabled', true);

                    underwriterAssurGarage.theWoodcraft.val(0);
                    underwriterAssurGarage.theWoodcraft.attr('readonly', true);
                    underwriterAssurGarage.theWoodcraftPercent.val(null);
                    underwriterAssurGarage.theWoodcraftPercent.attr('disabled', true);

                    underwriterAssurGarage.plasticMaterials.val(0);
                    underwriterAssurGarage.plasticMaterials.attr('readonly', true);
                    underwriterAssurGarage.plasticMaterialsPercent.val(null);
                    underwriterAssurGarage.plasticMaterialsPercent.attr('disabled', true);
                    break;
                case '1':
                    underwriterAssurGarage.gasBottleSale.attr('readonly', false);
                    underwriterAssurGarage.fuelDistribution.attr('readonly', false);
                    underwriterAssurGarage.theWoodcraft.attr('readonly', false);
                    underwriterAssurGarage.plasticMaterials.attr('readonly', false);
                    break;
            }
        },
        statusActivityGasBottle: function () {
            switch (underwriterAssurGarage.gasBottleSale.val()) {
                case '0':
                    underwriterAssurGarage.gasBottleSalePercent.val(null);
                    underwriterAssurGarage.gasBottleSalePercent.attr('disabled', true);
                    break;
                case '1':
                    if (!underwriterAssurGarage.gasBottleSale.attr('readonly')) {
                        underwriterAssurGarage.gasBottleSalePercent.attr('disabled', false);
                        underwriterAssurGarage.gasBottleSalePercent.attr('required', true);
                        break;
                    }
                    underwriterAssurGarage.gasBottleSale.val(0);
                    break;
            }
        },
        statusActivityFuelDistribution: function () {
            switch (underwriterAssurGarage.fuelDistribution.val()) {
                case '0':
                    underwriterAssurGarage.fuelDistributionPercent.val(null);
                    underwriterAssurGarage.fuelDistributionPercent.attr('disabled', true);
                    break;
                case '1':
                    if (!underwriterAssurGarage.fuelDistribution.attr('readonly')) {
                        underwriterAssurGarage.fuelDistributionPercent.attr('disabled', false);
                        underwriterAssurGarage.fuelDistributionPercent.attr('required', true);
                        break;
                    }
                    underwriterAssurGarage.fuelDistribution.val(0);
                    break;
            }
        },
        statusActivityWoodcraft: function () {
            switch (underwriterAssurGarage.theWoodcraft.val()) {
                case '0':
                    underwriterAssurGarage.theWoodcraftPercent.val(null);
                    underwriterAssurGarage.theWoodcraftPercent.attr('disabled', true);
                    break;
                case '1':
                    if (!underwriterAssurGarage.theWoodcraft.attr('readonly')) {
                        underwriterAssurGarage.theWoodcraftPercent.attr('disabled', false);
                        underwriterAssurGarage.theWoodcraftPercent.attr('required', true);
                        break;
                    }
                    underwriterAssurGarage.theWoodcraft.val(0);
                    break;
            }
        },
        statusActivityPlasticMaterials: function () {
            switch (underwriterAssurGarage.plasticMaterials.val()) {
                case '0':
                    underwriterAssurGarage.plasticMaterialsPercent.val(null);
                    underwriterAssurGarage.plasticMaterialsPercent.attr('disabled', true);
                    break;
                case '1':
                    if (!underwriterAssurGarage.plasticMaterials.attr('readonly')) {
                        underwriterAssurGarage.plasticMaterialsPercent.attr('disabled', false);
                        underwriterAssurGarage.plasticMaterialsPercent.attr('required', true);
                        break;
                    }
                    underwriterAssurGarage.plasticMaterials.val(0);
                    break;
            }
        },
        statusAnnexActivityPercent: function () {
            underwriterAssurGarage.statusActivityGasBottle();
            underwriterAssurGarage.statusActivityFuelDistribution();
            underwriterAssurGarage.statusActivityWoodcraft();
            underwriterAssurGarage.statusActivityPlasticMaterials();
        },
        statusThreeLastYearInsured: function () {
            // Siret
            switch (underwriterAssurGarage.threeLastYearInsured.val()) {
                case '0':
                    underwriterAssurGarage.lastInsurerName.attr('disabled', true);
                    underwriterAssurGarage.lastInsurerName.val(null);
                    underwriterAssurGarage.numberContract.attr('disabled', true);
                    underwriterAssurGarage.numberContract.val(null);
                    underwriterAssurGarage.effectiveAt.attr('disabled', true);
                    underwriterAssurGarage.effectiveAt.val(null);
                    underwriterAssurGarage.echeanceAt.attr('disabled', true);
                    underwriterAssurGarage.echeanceAt.val(null);
                    underwriterAssurGarage.terminationAt.attr('disabled', true);
                    underwriterAssurGarage.terminationAt.val(null);
                    underwriterAssurGarage.numberDisaster.attr('disabled', true);
                    underwriterAssurGarage.numberDisaster.val(null);
                    underwriterAssurGarage.amountDisaster.attr('disabled', true);
                    underwriterAssurGarage.amountDisaster.val(null);
                    underwriterAssurGarage.threeLastYearDisaster.attr('readonly', true);
                    underwriterAssurGarage.threeLastYearDisaster.val(0);
                    underwriterAssurGarage.threeLastYearInsured.val(0);
                    break;
                case '1':
                    underwriterAssurGarage.lastInsurerName.attr('disabled', false);
                    underwriterAssurGarage.lastInsurerName.attr('required', true);
                    underwriterAssurGarage.numberContract.attr('disabled', false);
                    underwriterAssurGarage.numberContract.attr('required', true);
                    underwriterAssurGarage.effectiveAt.attr('disabled', false);
                    underwriterAssurGarage.effectiveAt.attr('required', true);
                    underwriterAssurGarage.echeanceAt.attr('disabled', false);
                    underwriterAssurGarage.echeanceAt.attr('required', true);
                    underwriterAssurGarage.terminationAt.attr('disabled', false);
                    underwriterAssurGarage.terminationAt.attr('required', true);
                    underwriterAssurGarage.threeLastYearDisaster.attr('readonly', false);
                    underwriterAssurGarage.threeLastYearDisaster.attr('required', true);
                    break;
            }
        },
        statusThreeLastYearDisaster: function () {
            switch (underwriterAssurGarage.threeLastYearDisaster.val()) {
                case '0':
                    underwriterAssurGarage.numberDisaster.val(null);
                    underwriterAssurGarage.numberDisaster.attr('disabled', true);
                    underwriterAssurGarage.amountDisaster.val(null);
                    underwriterAssurGarage.amountDisaster.attr('disabled', true);
                    break;
                case '1':
                    if (!underwriterAssurGarage.threeLastYearDisaster.attr('readonly')) {
                        underwriterAssurGarage.numberDisaster.attr('disabled', false);
                        underwriterAssurGarage.numberDisaster.attr('required', true);
                        underwriterAssurGarage.amountDisaster.attr('disabled', false);
                        underwriterAssurGarage.amountDisaster.attr('required', true);
                        break;
                    }
                    underwriterAssurGarage.threeLastYearDisaster.val(0);
                    break;
            }
        },
        init: function () {
            underwriterAssurGarage.statusSiretCreating();
            underwriterAssurGarage.statusAnnexActivity();
            underwriterAssurGarage.statusAnnexActivityPercent();
            underwriterAssurGarage.statusThreeLastYearInsured();
            underwriterAssurGarage.statusThreeLastYearDisaster();
        },
        changeSiretCreating: function () {
            this.siretCreating.on('change', function () {
                underwriterAssurGarage.statusSiretCreating();
            })
        },
        changeAnnexActivity: function () {
            this.annexActivity.on('change', function () {
                underwriterAssurGarage.statusAnnexActivity();
                underwriterAssurGarage.statusAnnexActivityPercent();
            })
        },
        changeActivityGasBottle: function () {
            this.gasBottleSale.on('change', function () {
                underwriterAssurGarage.statusActivityGasBottle();
            })
        },
        changeActivityFuelDistribution: function () {
            this.fuelDistribution.on('change', function () {
                underwriterAssurGarage.statusActivityFuelDistribution();
            })
        },
        changeActivityWoodcraft: function () {
            this.theWoodcraft.on('change', function () {
                underwriterAssurGarage.statusActivityWoodcraft();
            })
        },
        changeActivityPlasticMaterials: function () {
            this.plasticMaterials.on('change', function () {
                underwriterAssurGarage.statusActivityPlasticMaterials();
            })
        },
        changeThreeLastYearInsured: function () {
            this.threeLastYearInsured.on('change', function () {
                underwriterAssurGarage.statusThreeLastYearInsured();
            })
        },
        changeThreeLastYearDisaster: function () {
            this.threeLastYearDisaster.on('change', function () {
                underwriterAssurGarage.statusThreeLastYearDisaster();
            })
        }
    }
    underwriterAssurGarage.init();
    underwriterAssurGarage.changeSiretCreating();
    underwriterAssurGarage.changeAnnexActivity();
    underwriterAssurGarage.changeActivityGasBottle();
    underwriterAssurGarage.changeActivityFuelDistribution();
    underwriterAssurGarage.changeActivityWoodcraft();
    underwriterAssurGarage.changeActivityPlasticMaterials();
    underwriterAssurGarage.changeThreeLastYearInsured();
    underwriterAssurGarage.changeThreeLastYearDisaster();
});