$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMicRcdPibPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMicRcdPibSelectInsured             = $('#amendment_ax_mic_rcd_pib_insured');
    let amendmentAxMicRcdPibSelectHasContract         = $('#amendment_ax_mic_rcd_pib_hasContract');
    let amendmentAxMicRcdPibSelectRcproInsured        = $('#amendment_ax_mic_rcd_pib_insuredRcpro');
    let amendmentAxMicRcdPibInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMicRcdPibSelectInsuredLastTwoYears = $('#amendment_ax_mic_rcd_pib_insuredLastTwoYears');
    let amendmentAxMicRcdPibInputNumberDisaster       = $('#amendment_ax_mic_rcd_pib_numberDisaster');
    let amendmentAxMicRcdPibInputAmountDisaster       = $('#amendment_ax_mic_rcd_pib_amountDisaster');
    let amendmentAxMicRcdPibDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMicRcdPibDateTermination           = $('#amendment_ax_mic_rcd_pib_dateTermination');
    let amendmentAxMicRcdPibSelectRcdFalseDeclaration = $('#amendment_ax_mic_rcd_pib_declaration');
    let amendmentAxMicRcdPibSelectUnpaid              = $('#amendment_ax_mic_rcd_pib_unpaid');
    let amendmentAxMicRcdPibSelectTerminateClaim      = $('#amendment_ax_mic_rcd_pib_terminateClaim');
    let amendmentAxMicRcdPibSelectResumptionLiability = $('#amendment_ax_mic_rcd_pib_resumptionLiability');
    let amendmentAxMicRcdPibSelectBoxOutsourcing      = $('#amendment_ax_mic_rcd_pib_outsourcing');
    let amendmentAxMicRcdPibSelectReceivership        = $('#amendment_ax_mic_rcd_pib_receivership');
    let amendmentAxMicRcdPibSelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMicRcdPibSchedule                  = $('#amendment_ax_mic_rcd_pib_schedule');
    let amendmentAxMicRcdPibAutoDebit                 = $('#amendment_ax_mic_rcd_pib_autoDebit');

    function setFormHidden() {
        amendmentAxMicRcdPibSelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibSelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibDateTermination.val("");
        amendmentAxMicRcdPibInsuredLastTwoYears.hide();
        amendmentAxMicRcdPibSelectInsuredLastTwoYears.val(1);
        amendmentAxMicRcdPibInputNumberDisaster.val(0);
        amendmentAxMicRcdPibInputAmountDisaster.val(0);
        amendmentAxMicRcdPibSelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibSelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibSelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibSelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMicRcdPibPassiveGroup.hide();
    }

    amendmentAxMicRcdPibSelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdPibPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMicRcdPibSelectInsured.val()) === 1) {
        amendmentAxMicRcdPibPassiveGroup.show();
    }

    if (parseInt(amendmentAxMicRcdPibSelectHasContract.val()) === 1) {
        amendmentAxMicRcdPibDateTerminationBlock.hide();
        amendmentAxMicRcdPibDateTermination.val("");
        amendmentAxMicRcdPibInsuredLastTwoYears.show();
    }

    amendmentAxMicRcdPibSelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdPibDateTerminationBlock.hide();
            amendmentAxMicRcdPibDateTermination.val("");
            amendmentAxMicRcdPibInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMicRcdPibInsuredLastTwoYears.hide();
        amendmentAxMicRcdPibSelectInsuredLastTwoYears.val(0);
        amendmentAxMicRcdPibDateTerminationBlock.show();
    });

    amendmentAxMicRcdPibSelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMicRcdPibSelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdPibSelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdPibSchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMicRcdPibAutoDebit.val(1);
        }
    });

    amendmentAxMicRcdPibInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdPibSelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
            return false;
        }
    });

    amendmentAxMicRcdPibSelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMicRcdPibSelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdPibInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdPibSelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMicRcdPibSelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});