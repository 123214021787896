$(function () {
    "use strict";

    // Get rcpro activities checkbox
    let activitiesRcpro = $("#underwriter_rcpro_activities").attr('checked', true);

    // Block the choice of activities to 6
    activitiesRcpro.click(function () {
        if (document.querySelectorAll('input[type="checkbox"]:checked').length > 6) {
            return false;
        }
    });

    let insuredRcpro = $('#underwriter_rcpro_insured');
    let rcproNumberDisaster = $('#underwriter_rcpro_numberDisaster');
    let rcproAmountDisaster = $('#underwriter_rcpro_amountDisaster');
    let isInsuredRcpro = $('.__is-insured-rcpro');
    let selectUnpaid = $('#underwriter_rcpro_unpaid');

    function setInsuredRcproHidden() {
        rcproNumberDisaster.val(0).prop('selected', 'selected');
        rcproAmountDisaster.val(0).prop('selected', 'selected');
        isInsuredRcpro.css('visibility', 'hidden');
    }

    selectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
        }
    });

    insuredRcpro.change(function () {
        if (this.value === '0') {
            setInsuredRcproHidden();
            return;
        }
        isInsuredRcpro.css('visibility', 'visible');
    });

    if (insuredRcpro.value === '0') {
        setInsuredRcproHidden();
    }

    $('#underwriter_rcpro_declaration').click(function () {
        if (this.value === '1') {
            this.value = '0';
            $('#_false-declaration').modal('show');
        }
    });

    let underwriterRcpro = {
        selectReceivership: $('#underwriter_rcpro_receivership'),
        selectSchedule: $('#underwriter_rcpro_schedule'),
        selectAutoDebit: $('#underwriter_rcpro_autoDebit'),
        initReceivership: function () {
            if (parseInt(underwriterRcpro.selectReceivership.val()) === 1) {
                underwriterRcpro.selectSchedule.val(1).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
            }
        },
        initSchedule: function () {
            if (parseInt(underwriterRcpro.selectSchedule.val()) === 4) {
                underwriterRcpro.selectAutoDebit.val(1).find('option').not(":selected").prop('disabled', 'disabled').css('background-color', '#676161');
            }
        },
        statusReceivership: function () {
            this.selectReceivership.on('change', function () {
                if (parseInt(this.value) === 1) {
                    $('#_schedule').modal('show');
                    underwriterRcpro.selectSchedule.val(1).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
                    underwriterRcpro.selectAutoDebit.find('option').not(":selected").prop('disabled', false).css('background-color', '');

                    return;
                }

                underwriterRcpro.selectSchedule.val(1).find('option').not(":selected").prop('disabled', false).css('background-color', '');
                underwriterRcpro.selectAutoDebit.val(1).find('option').not(":selected").prop('disabled', false).css('background-color', '');

            });
        },
        statusSchedule: function () {
            this.selectSchedule.on('change', function () {
                if (parseInt(this.value) === 4) {
                    $('#__term').modal('show');
                    underwriterRcpro.selectAutoDebit.val(1).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
                    return;
                }

                underwriterRcpro.selectAutoDebit.val(1).find('option').not(":selected").prop('disabled', false).css('background-color', '');
            })
        }
    };

    underwriterRcpro.initReceivership();
    underwriterRcpro.statusReceivership();
    underwriterRcpro.initSchedule();
    underwriterRcpro.statusSchedule();
});