global.$ = global.jQuery = $;
const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

// Setting Routing as global there
global.Routing = Routing;

// Core template styles
import "./inc/templates/actions";
import "./inc/templates/functions";

// Custom Yuna template
import "./inc/_images";
import "./inc/_chart_intranet";
import "./inc/_input-file_intranet";
import "./inc/_activities-DataTables";
import "./inc/_customer-DataTables";
import "./inc/_ajaxknppaginator";
import "./inc/_intranet";
import "./inc/_customer";
import "./inc/_survey";
import "./inc/_underwriter_rcd";
import "./inc/_underwriter_rcd_quick";
import "./inc/_underwriter_pne";
import "./inc/_legal";
import "./inc/_underwriter_dommages";
import "./inc/_underwriter_gfa";
import "./inc/_underwriter_rcpro";
import "./inc/_underwriter_mrp";
import "./inc/_underwriter_rcde";
import "./inc/_custom_do.js";
import "./inc/_assur_garage";

import './inc/ls/mic/rcd/constructor/_amendment';
import './inc/ax/mbb/rcd/structor/_amendment';
import './inc/ax/mbb/rcd/easy/_amendment';
import './inc/ax/mbb/rcd/pib/_amendment';
import './inc/ax/mbb/dommages/dommages/_intervenants';
import './inc/ax/mic/rcd/structor/_amendment';
import './inc/ax/mic/rcd/easy/_amendment';
import './inc/ax/mic/rcd/pib/_amendment';

import './inc/_dataTables.select.min';
import './inc/_select2';
import './inc/_datatable';
