$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let rcdPassiveGroup = $('._underwriter-rcd-lu-insured');
    let selectRcdInsured = $('#underwriter_rcd_insured');
    let selectRcdHasContract = $('#underwriter_rcd_hasContract');
    let selectRcproInsured = $('#underwriter_rcd_insuredRcpro');
    let insuredLastTwoYears = $('._underwriter-rcd-lu-insured-last-years');
    let selectInsuredLastTwoYears = $('#underwriter_rcd_insuredLastTwoYears');
    let inputNumberDisaster = $('#underwriter_rcd_numberDisaster');
    let inputAmountDisaster = $('#underwriter_rcd_amountDisaster');
    let dateTerminationBlock = $('._underwriter-rcd-lu-insured-date-termination');
    let dateTermination = $('#underwriter_rcd_dateTermination');
    let selectRcdFalseDeclaration = $('#underwriter_rcd_declaration');
    let selectUnpaid = $('#underwriter_rcd_unpaid');
    let selectUnpaidMaker = $('#underwriter_rcd_unpaid.__vsb-maker');
    let selectUnpaidPib = $('#underwriter_rcd_unpaid.__vsb-pib');
    let selectUnpaidPibMic = $('#underwriter_rcd_unpaid.__mic-pib');
    let selectUnpaidMic = $('#underwriter_rcd_unpaid.__mic');
    let selectTerminateClaim = $('#underwriter_rcd_terminateClaim');
    let selectResumptionLiability = $('#underwriter_rcd_resumptionLiability');
    let selectBoxOutsourcing = $('#underwriter_rcd_outsourcing');
    let selectBoxMakerOutsourcing = $('#underwriter_rcd_outsourcing.__vsb-maker');
    let outsourcingMakerGroup = $('.__outsourcingMaker');
    let selectReceivership = $('#underwriter_rcd_receivership');
    let selectReceivershipMic = $('#underwriter_rcd_receivership.__mic');
    let selectInsuredRcpro = $('#underwriter_insuredRcpro');
    let schedule = $('#underwriter_rcd_schedule');
    let autoDebit = $('#underwriter_rcd_autoDebit');
    let boxMbb = $('.__mbb');
    let boxVsb = $('.__vsb');
    let boxPool = $('.__pool');
    let boxCf = $('.__cf');
    let boxLs = $('.__ls');
    let packA = $('#__packA');
    let packB = $('#__packB');
    let selectRcdCustomer = $('#underwriter_rcd_customer');

    $(document).on('click', '#btn-customer-update-fields', function (e) {
        var $form = $(document).find('#customer_update_fields');
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize()
        })
            .done(function (data) {
                if (data.showModal) {
                    $('#_customer-number-employees .modal-body').html(data.content);
                    $('#_customer-number-employees').modal('show');
                }
                else {
                    $('#_customer-number-employees').modal('hide');
                }
            });
    });

    function setFormHidden() {
        selectRcdHasContract.val(0).prop('selected', 'selected');
        selectRcproInsured.val(0).prop('selected', 'selected');
        dateTermination.val("");
        insuredLastTwoYears.hide();
        selectInsuredLastTwoYears.val(0);
        inputNumberDisaster.val(0);
        inputAmountDisaster.val(0);
        selectUnpaid.val(0).prop('selected', 'selected');
        selectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        selectInsuredRcpro.val(0).prop('selected', 'selected');
        rcdPassiveGroup.hide();
        selectResumptionLiability.val(0);
    }

    selectRcdInsured.change(function () {
        if (parseInt(this.value) === 1) {
            rcdPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(selectRcdInsured.val()) === 1) {
        rcdPassiveGroup.show();
    }

    if (parseInt(selectRcdHasContract.val()) === 1) {
        dateTerminationBlock.hide();
        dateTermination.val("");
        insuredLastTwoYears.show();
    }

    selectRcdHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            dateTerminationBlock.hide();
            dateTermination.val("");
            insuredLastTwoYears.show();
            return;
        }
        insuredLastTwoYears.hide();
        selectInsuredLastTwoYears.val(0);
        dateTerminationBlock.show();
    });

    function resumptionLiability() {
        selectResumptionLiability.find('option').not(":selected").prop('disabled', false).css('background-color', '');
        if (parseInt(selectRcdInsured.val()) === 1 && parseInt(selectRcdHasContract.val()) === 1) {
            selectResumptionLiability.val(0).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
        }
    }

    function resumptionLiabilityMbb() {
        selectResumptionLiability.find('option').not(":selected").prop('disabled', false).css('background-color', '');
        if ((parseInt(selectRcdInsured.val()) === 1 && parseInt(selectRcdHasContract.val()) === 1) || parseInt(selectReceivership.val()) === 1) {
            selectResumptionLiability.val(0).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
        }
    }

    if (boxMbb[0]) {
        resumptionLiabilityMbb();
        selectRcdInsured.add(selectRcdHasContract).on('change', resumptionLiabilityMbb);
    }

    if (boxVsb[0]) {
        function vsbScheduleRule() {
            autoDebit.find('option').not(":selected").prop('disabled', false).css('background-color', '');
            if (parseInt(schedule.val()) === 3 || parseInt(schedule.val()) === 4) {
                autoDebit.val(1).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
            }
        }

        vsbScheduleRule();
        schedule.on('change', vsbScheduleRule);

        resumptionLiability();
        selectRcdInsured.add(selectRcdHasContract).on('change', resumptionLiability);
    }

    selectResumptionLiability.not('.__building, .__tile, .__batiz').change(function () {
        if (boxPool.attr('data-action') && parseInt(this.value) === 1 && parseInt(selectReceivership.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    selectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    schedule.change(function () {
        if (parseInt(this.value) === 4) {
            $('#underwriter_rcd_autoDebit').val(1);
        }
    });

    inputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    selectBoxOutsourcing.not('.__vsb-maker').change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
            return false;
        }
    });

    selectReceivershipMic.change(function () {
        if(parseInt(this.value) === 1) {
            //disable fract. options except annually
            schedule.val(1);
            schedule.children('option[value=2]').attr({'disabled': true});
            schedule.children('option[value=3]').attr({'disabled': true});
            schedule.children('option[value=4]').attr({'disabled': true});

            $('#_schedule-receivership-mic').modal('show');
        } else {
            //enable fract. options
            schedule.children('option[value=2]').attr({'disabled': false});
            if(parseInt(selectUnpaidMic.val()) != 1) {
                schedule.children('option[value=3]').attr({'disabled': false});
                schedule.children('option[value=4]').attr({'disabled': false});
            }
        }
    });

    selectReceivership.not('.__mic').change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
            if(boxLs[0] || boxCf[0]){
                schedule.val(1);
                schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
                schedule.children('option[value=3]').attr({'disabled': true, 'selected': false});
                schedule.children('option[value=4]').attr({'disabled': true, 'selected': false});

                if(parseInt(selectUnpaid.val()) === 0) {
                    schedule.children('option[value=2]').attr({'disabled': true, 'selected': false});
                    autoDebit.children('option[value=0]').attr({'disabled': false, 'selected': true});
                    autoDebit.children('option[value=1]').attr({'disabled': true, 'selected': false});
                }
            }

            if (boxPool.attr('data-action')) {
                schedule.val(1);
                schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
                schedule.children('option[value=4]').attr({'disabled': true, 'selected': false});
                selectResumptionLiability.val(0).prop('selected', 'selected');
            }

            if (boxMbb[0]) {
                selectResumptionLiability.val(0).find('option').not(":selected").attr("disabled", "disabled").css('background-color', '#676161');
                schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
                schedule.children('option[value=3]').attr({'disabled': true, 'selected': false}).css('background-color', '#676161');
                schedule.children('option[value=4]').attr({'disabled': true, 'selected': false}).css('background-color', '#676161');
            }

            return;
        }

        if((boxLs[0] || boxCf[0]) && parseInt(this.value) === 0) {
            schedule.children('option[value=2]').attr({'disabled': false});
            schedule.children('option[value=3]').attr({'disabled': false});
            schedule.children('option[value=4]').attr({'disabled': false});

            autoDebit.children('option[value=1]').attr({'disabled': false});
        }

        if (boxPool.attr('data-action') && parseInt(selectUnpaid.val()) === 0) {
            schedule.children('option[value=4]').attr({'disabled': false});
        }

        if (boxMbb[0]) {
            resumptionLiabilityMbb()
            schedule.children('option[value=3]').attr({'disabled': false}).css('background-color', '');
            schedule.children('option[value=4]').attr({'disabled': false}).css('background-color', '');
        }
    });

    selectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    inputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    selectUnpaid.not('.__vsb-maker, .__vsb-pib, .__mic, .__mic-pib').change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');

            if (boxPool.attr('data-action')) {
                schedule.val(1);
                schedule.children('option[value=4]').attr({'disabled': true, 'selected': false});
                schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
            }

            if(boxLs[0] && parseInt(selectReceivership.val()) === 1) {
                schedule.children('option[value=2]').attr({'disabled': false});
                autoDebit.children('option[value=1]').attr({'disabled': false});
                autoDebit.children('option[value=0]').attr({'disabled': false});
            }

            return;
        }

        if(boxLs[0] && parseInt(selectReceivership.val()) === 1) {
            schedule.val(1);
            schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
            schedule.children('option[value=2]').attr({'disabled': true});
            autoDebit.val(0);
            autoDebit.children('option[value=1]').attr({'disabled': true});
        }

        if (boxPool.attr('data-action') && parseInt(selectReceivership.val()) === 0) {
            schedule.children('option[value=4]').attr({'disabled': false});
        }
    });

    // For RCD maker (batirisk, vsb)
    selectUnpaidMaker.change(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_no-unpaid-subscription').modal('show');
        }
    });

    selectUnpaidMic.change(function () {
        //unpaid selected
        if (parseInt(this.value) === 1) {
            //select and disable choice resumptionliability
            selectResumptionLiability.val(0);
            selectResumptionLiability.children('option[value=1]').attr({'disabled': true});


            //diable fract options trimester + monthly
            if(parseInt(schedule.val()) > 1 ) {
                schedule.val(1)
            }
            schedule.children('option[value=2]').attr({'disabled': true}); // disable semiannual
            schedule.children('option[value=3]').attr({'disabled': true}); // disable trimester
            schedule.children('option[value=4]').attr({'disabled': true}); // disable monthly

            //select autodebit + remove possibility to change
            autoDebit.val(1);
            autoDebit.children('option[value=0]').attr({'disabled': true});

            //show modal message
            $('#_unpaid-subscription-mic').modal('show');
        } else {
            selectResumptionLiability.children('option[value=0]').attr({'disabled': false});

            //enable fract. options trimester + monthly if not receivership
            if( parseInt(selectReceivership.val()) != 1) {
                schedule.children('option[value=2]').attr({'disabled': false}); // enable semiannual
                schedule.children('option[value=3]').attr({'disabled': false}); // enable trimester
                schedule.children('option[value=4]').attr({'disabled': false}); // enable monthly
            }

            //enable possibility to change autodebit
            autoDebit.children('option[value=0]').attr({'disabled': false});
            selectResumptionLiability.children('option[value=1]').attr({'disabled': false});
        }
    });

    selectUnpaidPibMic.change(function () {
        //unpaid selected
        if (parseInt(this.value) === 1) {
            //diable fract options trimester + monthly
            if(parseInt(schedule.val()) > 1 ) {
                schedule.val(1)
            }

            schedule.children('option[value=2]').attr({'disabled': true}); // disable semiannual
            schedule.children('option[value=3]').attr({'disabled': true}); // disable trimester
            schedule.children('option[value=4]').attr({'disabled': true}); // disable monthly

            //select autodebit + remove possibility to change
            autoDebit.val(1);
            autoDebit.children('option[value=0]').attr({'disabled': true});

            //show modal message
            $('#_unpaid-subscription-mic-pib').modal('show');
        } else {
            //enable fract. options trimester + monthly
            schedule.children('option[value=2]').attr({'disabled': false}); // disable semiannual
            schedule.children('option[value=3]').attr({'disabled': false}); // disable trimester
            schedule.children('option[value=4]').attr({'disabled': false}); // disable monthly

            //enable possibility to change autodebit
            autoDebit.children('option[value=0]').attr({'disabled': false});
        }
    });

    // For RCD PIB (batirisk, vsb)
    selectUnpaidPib.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule_annual').modal('show');
        }
    });

    // For RCD PIB (mic)
    selectUnpaidPib.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule_annual').modal('show');
        }
    });

    // colappse for pack activity pool
    packA.hide();
    packB.hide();


    let packValue = $('#underwriter_rcd_pack').val();

    if (packValue === '0') {
        packA.collapse('show');
        $("#__packABtn").addClass('active');
        packA.show();
    }

    if (packValue === '1') {
        packB.collapse('show');
        $("#__packBBtn").addClass('active');
        packB.show();
    }

    $('#__packABtn').click(function () {
        packB.collapse('hide');
        $("#__packB :input").val(0);
        packA.collapse('show');
        packA.show();
        packB.hide();
    });

    $('#__packBBtn').click(function () {
        packA.collapse('hide');
        $("#__packA :input").val(0);
        packB.collapse('show');
        packB.show();
        packA.hide();
    });

    $('.toggle-input').click(function () {
        $('#underwriter_rcd_pack').val($(this).attr('data-value'));
    });

    // check if percentage get over 100 %
    let percent = $('.__poolPercent');

    percent.change(function () {
        let valueArray = $('.__poolPercent').map(function () {
            return this.value;
        }).get();

        let sum = 0;
        valueArray.forEach(function (num) {
            sum += parseFloat(num) || 0;
        });
        if (sum > 100) {
            $('#_over_percent').modal('show');
            this.value = 0;
        }
    });

    if (boxPool.attr('data-action')) {
        let autoDebitPool = $('#underwriter_rcd_autoDebit');
        autoDebitPool.val(1);
        autoDebitPool.change(function () {
            $(this).val(1)
        });
        if (boxPool.attr('data-action') === 'edit') {
            if (parseInt(selectReceivership.val()) === 1) {
                schedule.children('option[value=4]').attr({'disabled': true, 'selected': false});
                schedule.children('option[value=1]').attr({'disabled': false, 'selected': true});
            }
        }
    }

    $('.__pool input[type="submit"]').on('click', function () {
        let valueArray = $('.__poolPercent').map(function () {
            return this.value;
        }).get();

        let sum = 0;
        valueArray.forEach(function (num) {
            sum += parseFloat(num) || 0;
        });

        if (sum !== 100) {
            $('#_sum_percent').modal('show');
            return false;
        }
    });

    // RCD MAKER
    let $addButton = $('.__add-activity-vsb-maker');
    var $newLinkLi = $('<li class="list-group-item"></li>').append($addButton);
    var $collectionHolder = $('ul.__activities-maker');

    $collectionHolder.find('li').each(function () {
        addActivityFormDeleteLink($(this));
    });

    $collectionHolder.append($newLinkLi);
    $collectionHolder.data('index', $collectionHolder.find('input').length);

    $addButton.on('click', function (e) {
        addTagForm($collectionHolder, $newLinkLi);
    });

    function addTagForm($collectionHolder, $newLinkLi) {
        let prototype = $collectionHolder.data('prototype');
        let index = $collectionHolder.data('index');
        let newForm = prototype;

        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        let $newFormLi = $('<li class="mb-2 list-group-item row"></li>').append(newForm);
        $newLinkLi.before($newFormLi);

        addActivityFormDeleteLink($newFormLi);
    }

    function addActivityFormDeleteLink($activityFormLi) {
        var $removeFormButton = $('<button type="button" class="btn btn-theme-dark btn--icon ml-2"><i class="zwicon-trash"></i></button>');
        $activityFormLi.find('input').addClass('form-control d-inline col-11');
        $activityFormLi.append($removeFormButton);

        $removeFormButton.on('click', function (e) {
            $activityFormLi.remove();
        });
    }

    function vsbOutsourcingMaker() {
        outsourcingMakerGroup.hide();
        if (parseInt(selectBoxMakerOutsourcing.val()) === 1) {
            outsourcingMakerGroup.show();
        }
    }

    vsbOutsourcingMaker();
    selectBoxMakerOutsourcing.on('change', vsbOutsourcingMaker);

    selectRcdCustomer.change(function (e) {
        if ($(this).val() !== '') {
            $.ajax({
                url: Routing.generate('customer_update_number_employee_ajax', {id: $(this).val()}),
                data: {field: 'numberEmployee'}
            })
                .done(function (data) {
                    $('#_customer-number-employees .modal-body').html(data.content);
                    $('#_customer-number-employees').modal('show');
                });
        }
    });

    if ($('#has-customer-update').length && $('#has-customer-update').val() !== '') {
        $.ajax({
            url: Routing.generate('customer_update_number_employee_ajax', {id:$('#has-customer-update').val()}),
            data: {field:'numberEmployee'}
        })
            .done(function (data) {
                $('#_customer-number-employees .modal-body').html(data.content);
                $('#_customer-number-employees').modal('show');
            });
    }

    if(selectReceivershipMic.val() && parseInt(selectReceivershipMic.val()) === 1){
        schedule.val(1);
        schedule.children('option[value=2]').attr({'disabled': true});
        schedule.children('option[value=3]').attr({'disabled': true});
        schedule.children('option[value=4]').attr({'disabled': true});
    }
});
