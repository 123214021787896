'use strict';

var InputFileIntranet = function() {
  // Set options
  if (arguments[0] && typeof arguments[0] === 'object') {
    this.options = arguments[0];
  }

  // Get all the file input fields
  let fields = document.querySelectorAll('input[type="file"].' + this.options.class);
  for (var i = 0; i < fields.length; i++) {
    this.createField(fields[i]);
  }
};

InputFileIntranet.prototype.createField = function(field) {
  let options = this.options || {};

  // Create drop area
  let dropArea = document.createElement('div');
  dropArea.className = 'inf__drop-area';
  field.parentNode.insertBefore(dropArea, field);
  dropArea.appendChild(field);

  // Create button
  let btn = document.createElement('span');
  btn.className = 'inf__btn';
  btn.innerText = options.buttonText || field.getAttribute('multiple') ? 'Choisir des fichiers' : 'Choisir un fichier';
  dropArea.insertBefore(btn, field);

  // Create hint element
  let hint = document.createElement('span');
  hint.className = 'inf__hint';
  hint.innerText = options.hint || field.getAttribute('multiple') ? 'ou faites glisser et déposer les fichiers ici' : 'ou faites glisser et déposer le fichier ici';
  dropArea.insertBefore(hint, field);

  // Highlight drag area
  addMultiEventListener(field, 'dragenter click focus', function() {
    dropArea.classList.add('is-active');
  });

  // Back to normal state
  addMultiEventListener(field, 'dragleave drop blur', function() {
    dropArea.classList.remove('is-active');
  });

  // Update inner text
  field.addEventListener('change', function() {
    let filesCount = field.files.length;

    if (filesCount === 0) {
      hint.innerText = options.hint || 'ou faites glisser et déposez les fichiers ici';
      dropArea.classList.remove('is-active');
    } else if (filesCount === 1) {
      hint.innerText = field.value.split('\\').pop();
      dropArea.classList.add('is-active');
    } else {
      hint.innerText = filesCount + ' ' + (options.message || 'fichiers choisis');
      dropArea.classList.add('is-active');
    }
  });
};

// Listens to multiple events
function addMultiEventListener(el, e, fn) {
  let events = e.split(' ');

  for (let i = 0; i < events.length; i++) {
    el.addEventListener(events[i], fn, false);
  }
}

/**
 * @type {jQuery|HTMLElement}
 * Init Js InputFileIntranet
 */
new InputFileIntranet({
  class: "__inputFileVoucher",
});