$(function () {
    "use strict";

    function getMonths() {
        return [[1, 'Janvier'], [2, 'Février'], [3, 'Mars'], [4, 'Avril'], [5, 'Mai'], [6, 'Juin'], [7, 'Juillet'], [8, 'Août'], [9, 'Septembre'], [10, 'Octobre'], [11, 'Novembre'], [12, 'Décembre']];
    }

    /**
     * @return {number}
     */
    function getYear() {
        return (new Date()).getFullYear();
    }

    /**
     * Dashboard
     */
    if ($('.__dashboard').length > 0) {

        let data = $('#stats').data('stats');
        // format data for french currency
        let customIntl = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            currencyDisplay: "symbol"
        });

        /**
         * Underwriter status in global
         * @type {jQuery|HTMLElement}
         */
        let customerPerMonth = $('#__chart_customer_per_month');
        let hasCustomer = data.customerByMonth.filter(function (number) {
            return number > 0;
        });

        // Dont display chart if the array is empty
        if (hasCustomer.length > 0) {

            $.plot(customerPerMonth,
                [{
                    label: 'Nombre de clients par mois de cette année',
                    data: [
                        [1, data.customerByMonth[0]],
                        [2, data.customerByMonth[1]],
                        [3, data.customerByMonth[2]],
                        [4, data.customerByMonth[3]],
                        [5, data.customerByMonth[4]],
                        [6, data.customerByMonth[5]],
                        [7, data.customerByMonth[6]],
                        [8, data.customerByMonth[7]],
                        [9, data.customerByMonth[8]],
                        [10, data.customerByMonth[9]],
                        [11, data.customerByMonth[10]],
                        [12, data.customerByMonth[11]],
                    ],
                    bars: {
                        order: 0,
                        fillColor: "#fff"
                    },
                    color: "#fff"
                }],
                {
                    series: {
                        bars: {
                            show: true,
                            barWidth: .5,
                            fill: 1,
                            lineWidth: 0
                        }
                    },
                    grid: {
                        borderWidth: 1,
                        borderColor: "rgba(255,255,255,0.1)",
                        show: true,
                        hoverable: true,
                        clickable: true
                    },
                    yaxis: {
                        tickColor: "rgba(255,255,255,0.1)",
                        tickDecimals: 0,
                        font: {
                            lineHeight: 13,
                            style: "normal",
                            color: "rgba(255,255,255,0.75)",
                            size: 11
                        },
                        shadowSize: 0
                    },
                    xaxis: {
                        axisLabel: 'Mois',
                        ticks: getMonths(),
                        tickColor: "rgba(255,255,255,0.1)",
                        tickDecimals: 0,
                        font: {
                            lineHeight: 13,
                            style: "normal",
                            color: "rgba(255,255,255,0.75)",
                            size: 11
                        },
                        shadowSize: 0
                    },
                    legend: {
                        container: ".flot-chart-legends--customer_per_month",
                        backgroundOpacity: .5,
                        noColumns: 0,
                        lineWidth: 0,
                        labelBoxBorderColor: "rgba(255,255,255,0)"
                    }
                }
            );

        } else {
            customerPerMonth.closest('section').remove();
        }

        let underwritersAmount = $('#__chart_underwriter_amount');
        if (data.amountRcdProgress > 0 || data.amountRcdPending > 0 || data.amountRcdContract > 0) {
            let d = [
                {
                    data: data.amountRcdProgress,
                    color: "rgba(255,255,255,0.45)",
                    label: "Montant des devis en cours"
                },
                {
                    data: data.amountRcdPending,
                    color: "rgba(255,255,255,0.75)",
                    label: "Montant des contrats temporaires"
                },
                {
                    data: data.amountRcdContract,
                    color: "rgba(255,255,255,0.95)",
                    label: "Montant des contrats"
                }
            ]

            $.plot(underwritersAmount, d,
                {
                    series: {
                        pie: {
                            innerRadius: .5,
                            show: !0,
                            stroke: {
                                width: 0
                            }
                        }
                    },
                    legend: {
                        container: ".flot-chart-legend--underwriter_amount",
                        noColumns: 0,
                        lineWidth: 0,
                        labelBoxBorderColor: "rgba(255,255,255,0)"
                    }
                }
            );

        } else {
            underwritersAmount.closest('section').remove();
        }

    }
});