$(function () {
    $(document).ready(function () {

        /**
         *
         * @type {jQuery|HTMLElement}
         * customer have not a contract, display dateTermination
         */
        let rcdePassiveGroup = $('._underwriter-rcde-lu-insured');
        let selectRcdeInsuredRcd = $('#underwriter_rcde_insuredRcd');
        let selectRcdeInsuredRcpro = $('#underwriter_rcde_insuredRcpro');
        let selectRcdeHasContract = $('#underwriter_rcde_hasContract');
        let insuredLastTwoYears = $('._underwriter-rcde-lu-insured-last-years');
        let inputNumberDisaster = $('#underwriter_rcde_numberDisaster');
        let inputAmountDisaster = $('#underwriter_rcde_amountDisaster');
        let dateTerminationBlock = $('._underwriter-rcde-lu-insured-date-termination');
        let dateTermination = $('#underwriter_rcde_dateTermination');
        let selectRcdeFalseDeclaration = $('#underwriter_rcde_declaration');
        let selectUnpaid = $('#underwriter_rcde_unpaid');
        let selectTerminateClaim = $('#underwriter_rcde_terminateClaim');
        let selectBoxOutsourcing = $('#underwriter_rcde_outsourcing');
        let selectReceivership = $('#underwriter_rcde_receivership');
        let selectRcdeCustomer = $('#underwriter_rcde_customer');

        function setFormHidden() {
            selectRcdeHasContract.val(0).attr('selected', 'selected');
            dateTermination.val("");
            insuredLastTwoYears.val(0);
            inputNumberDisaster.val(0);
            inputAmountDisaster.val(0);
            selectRcdeFalseDeclaration.val(0).attr('selected', 'selected');
            rcdePassiveGroup.hide();
        }

        if (parseInt(selectRcdeInsuredRcd.val()) === 1) {
            rcdePassiveGroup.show();
        } else {
            setFormHidden();
        }

        selectRcdeInsuredRcd.change(function () {
            if (parseInt(this.value) === 1) {
                rcdePassiveGroup.show();
                return;
            }
            setFormHidden();
        });

        if (parseInt(selectRcdeHasContract.val()) === 1) {
            dateTerminationBlock.hide();
            dateTermination.val("");
            insuredLastTwoYears.show();
        }

        selectRcdeHasContract.change(function () {
            if (parseInt(this.value) === 1) {
                dateTerminationBlock.hide();
                dateTermination.val("");
                insuredLastTwoYears.show();
                return;
            }
            insuredLastTwoYears.hide();
            dateTerminationBlock.show();
        });

        selectRcdeFalseDeclaration.change(function () {
            if (parseInt(this.value) === 1) {
                $('#_false-declaration').modal('show');
                this.value = 0;
            }
        });

        inputAmountDisaster.change(function () {
            if (this.value === '') {
                this.value = 0;
            }
        });

        selectBoxOutsourcing.change(function () {
            if (parseInt(this.value) === 1) {
                $('#_outsourcing').modal('show');
                this.value = 0;
                return false;
            }
        });
        
        selectTerminateClaim.change(function () {
            if (parseInt(this.value) === 1) {
                $('#_terminate-claim').modal('show');
                this.value = 0;
            }
        });

        inputNumberDisaster.change(function () {
            if (this.value === '') {
                this.value = 0;
            }
        });

        let inputNumberYearsExperience = $('#underwriter_rcde_numberYearsExperience');

        inputNumberYearsExperience.change(function () {
            if (this.value < 3) {
                $('#_number-years-experience').modal('show');
                this.value = 0;
            }
        });

        selectRcdeCustomer.change(function (e) {
            if ($(this).val() !== '') {
                $.ajax({
                    url: Routing.generate('customer_update_number_employee_ajax', {id:$(this).val()}),
                    data: {field:'numberEmployee'}
                })
                    .done(function (data) {
                        $('#_customer-number-employees .modal-body').html(data.content);
                        $('#_customer-number-employees').modal('show');
                    });
            }
        });
    });
});