import {_customdo} from "./_customdo";
$(document).ready(function () {
    let customerDoCustomer = $('#custom_do_customer');
    customerDoCustomer.change(function (e) {
        _customdo.changeCustomer({
                'uuid': customerDoCustomer.find(':selected').data('uuid'),
                'email': customerDoCustomer.find(':selected').data('email'),
                'legal_status': customerDoCustomer.find(':selected').data('legal_status'),
                'siret': customerDoCustomer.find(':selected').data('siret'),
                'name': customerDoCustomer.find(':selected').data('name'),
                'address': customerDoCustomer.find(':selected').data('address'),
                'post_code': customerDoCustomer.find(':selected').data('post_code'),
                'city': customerDoCustomer.find(':selected').data('city'),
                'country': customerDoCustomer.find(':selected').data('country')
            }
        );
    });

    let allRadioBox = ['custom_do_informationGeneral', 'custom_do_informationGeologic', 'custom_do_building'];
    allRadioBox.forEach(function (selector) {
        $('.' + selector).click(function () {
            let value = this.value.replace('A', '').replace('B', '');
            $('.' + selector).filter(function (index, data) {
                return (data.value === value + 'A') || (data.value === value + 'B');
            }).not(this).prop('checked', false);
        });
    });

    $('.custom_do_slice').click(function () {
        $('.custom_do_slice').not(this).prop('checked', false);
    });
    $(".add_row").on("click", function(){
        var dataId = $(this).attr("data-id");
        let inputs = $('.input-dynamic-'+dataId);
        let ek = inputs.map((_, el) => el.id).get();
        let currentMax = Math.max(...ek);
        let max = ($.isNumeric(currentMax)?currentMax:0) + 1;
        $('#list-'+dataId+' tbody').append('<tr><td  > <input type="text" id="' + max + '" class="input-dynamic-'+dataId+'" name="custom_do['+dataId+'][' + max + '][1][]" value=" " style="width:100%"></td><td> <input type="text" class="input-dynamic-'+dataId+'" name="custom_do['+dataId+'][' + max + '][2][]" id="' + max + '"  value=" " style="width:100%"></td></tr>');
        event.stopPropagation();
        event.preventDefault();
    });

    if(!!customerDoCustomer && !!customerDoCustomer.find(':selected').data('uuid')){
        _customdo.changeCustomer({
                'uuid': customerDoCustomer.find(':selected').data('uuid'),
                'email': customerDoCustomer.find(':selected').data('email'),
                'legal_status': customerDoCustomer.find(':selected').data('legal_status'),
                'siret': customerDoCustomer.find(':selected').data('siret'),
                'name': customerDoCustomer.find(':selected').data('name'),
                'address': customerDoCustomer.find(':selected').data('address'),
                'post_code': customerDoCustomer.find(':selected').data('post_code'),
                'city': customerDoCustomer.find(':selected').data('city'),
                'country': customerDoCustomer.find(':selected').data('country')
            }
        );
    }

});
