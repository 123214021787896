$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMicRcdStructorPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMicRcdStructorSelectInsured             = $('#amendment_ax_mic_rcd_structor_insured');
    let amendmentAxMicRcdStructorSelectHasContract         = $('#amendment_ax_mic_rcd_structor_hasContract');
    let amendmentAxMicRcdStructorSelectRcproInsured        = $('#amendment_ax_mic_rcd_structor_insuredRcpro');
    let amendmentAxMicRcdStructorInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMicRcdStructorSelectInsuredLastTwoYears = $('#amendment_ax_mic_rcd_structor_insuredLastTwoYears');
    let amendmentAxMicRcdStructorInputNumberDisaster       = $('#amendment_ax_mic_rcd_structor_numberDisaster');
    let amendmentAxMicRcdStructorInputAmountDisaster       = $('#amendment_ax_mic_rcd_structor_amountDisaster');
    let amendmentAxMicRcdStructorDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMicRcdStructorDateTermination           = $('#amendment_ax_mic_rcd_structor_dateTermination');
    let amendmentAxMicRcdStructorSelectRcdFalseDeclaration = $('#amendment_ax_mic_rcd_structor_declaration');
    let amendmentAxMicRcdStructorSelectUnpaid              = $('#amendment_ax_mic_rcd_structor_unpaid');
    let amendmentAxMicRcdStructorSelectTerminateClaim      = $('#amendment_ax_mic_rcd_structor_terminateClaim');
    let amendmentAxMicRcdStructorSelectResumptionLiability = $('#amendment_ax_mic_rcd_structor_resumptionLiability');
    let amendmentAxMicRcdStructorSelectBoxOutsourcing      = $('#amendment_ax_mic_rcd_structor_outsourcing');
    let amendmentAxMicRcdStructorSelectReceivership        = $('#amendment_ax_mic_rcd_structor_receivership');
    let amendmentAxMicRcdStructorSelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMicRcdStructorSchedule                  = $('#amendment_ax_mic_rcd_structor_schedule');
    let amendmentAxMicRcdStructorAutoDebit                 = $('#amendment_ax_mic_rcd_structor_autoDebit');

    function setFormHidden() {
        amendmentAxMicRcdStructorSelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorSelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorDateTermination.val("");
        amendmentAxMicRcdStructorInsuredLastTwoYears.hide();
        amendmentAxMicRcdStructorSelectInsuredLastTwoYears.val(1);
        amendmentAxMicRcdStructorInputNumberDisaster.val(0);
        amendmentAxMicRcdStructorInputAmountDisaster.val(0);
        amendmentAxMicRcdStructorSelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorSelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorSelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMicRcdStructorPassiveGroup.hide();
    }

    amendmentAxMicRcdStructorSelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdStructorPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMicRcdStructorSelectInsured.val()) === 1) {
        amendmentAxMicRcdStructorPassiveGroup.show();
    }

    if (parseInt(amendmentAxMicRcdStructorSelectHasContract.val()) === 1) {
        amendmentAxMicRcdStructorDateTerminationBlock.hide();
        amendmentAxMicRcdStructorDateTermination.val("");
        amendmentAxMicRcdStructorInsuredLastTwoYears.show();
    }

    amendmentAxMicRcdStructorSelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdStructorDateTerminationBlock.hide();
            amendmentAxMicRcdStructorDateTermination.val("");
            amendmentAxMicRcdStructorInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMicRcdStructorInsuredLastTwoYears.hide();
        amendmentAxMicRcdStructorSelectInsuredLastTwoYears.val(0);
        amendmentAxMicRcdStructorDateTerminationBlock.show();
    });

    amendmentAxMicRcdStructorSelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMicRcdStructorSelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorSelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorSchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMicRcdStructorAutoDebit.val(1);
        }
    });

    amendmentAxMicRcdStructorInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorSelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorSelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMicRcdStructorSelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdStructorSelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMicRcdStructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});