$(function () {
    "use strict";
    let _legalContract = {
        effectiveDate: $('#legal_contract_effectiveDate'),
        packOne: $('#legal_contract_selectedPack_1'),
        packSecond: $('#legal_contract_selectedPack_2'),
        packSecondCount: $('#legal_contract_packTwoCount'),
        packSecondTotal: $('#option_2_total'),
        option: $('#legal_contract_optionPlus'),
        optionCount: $('#legal_contract_optionPlusCount'),
        optionTotal: $('#option_3_total'),
        ttcTotal: $('#jurifisc_ttc_total'),
        ttcTotalReply: $('#jurifisc_ttc_total_reply'),
        commCourtier: $('#jurifisc_comm_coutier'),
        dateReview: $('#date_review'),
        errorsLegalEmployees: $('#errors_jurifisc_employee'),
        errorsLegaOptions: $('#errors_jurifisc_subscriber'),
        errorsLegalEmployeesSubmit: $('#errors_jurifisc_employee_submit'),
        errorsLegaOptionsSubmit: $('#errors_jurifisc_subscriber_submit'),
        subscriptionCost: $('#legal_contract_subscriptionCost'),
        ttcTotalWithSubscriptionCost: $('#jurifisc_ttc_total_with_cost'),
        feesCost: $('#fees_cost'),
        prices: {
            packOne: 195,
            packSecond: 240,
            packSecondUnit: 30,
            optionUnit: 65,
            commCourtier: 25,
            htRate: 1 / 1.134,
        },
        isPackOne: function () {
            return this.packOne.prop("checked") === true
        },
        isPackSecond: function () {
            return this.packSecond.prop("checked") === true
        },
        hadOption: function () {
            return this.option.prop("checked") === true
        },
        getPackSecondTotal: function () {
            return this.packSecondCount.val() * this.prices.packSecondUnit + this.prices.packSecond
        },
        setPackSecondTotal: function ($value) {
            this.packSecondTotal.text($value);
        },
        reloadPackSecondTotal() {
            this.setPackSecondTotal(this.getPackSecondTotal())
        },
        getOptionTotal: function () {
            return this.optionCount.val() * this.prices.optionUnit
        },
        setOptionTotal: function ($value) {
            this.optionTotal.text($value);
        },
        reloadOptionTotal() {
            this.setOptionTotal(this.getOptionTotal())
        },
        getSubscriptionCost: function () {
            if(!this.subscriptionCost.val()){
                return 0;
            }
            return  parseFloat(this.subscriptionCost.val());
        },
        calculatePrime: function () {
            let self = this;
            let base = 0;
            let optionValue = 0;
            if (self.isPackOne()) {
                base = self.prices.packOne;
            } else if (self.isPackSecond()) {
                base =  self.prices.packSecond + (self.prices.packSecondUnit * self.packSecondCount.val());
                self.packSecondTotal.text(base);
            }

            if (self.hadOption()) {
                optionValue = self.optionCount.val() * self.prices.optionUnit;
                self.optionTotal.text(optionValue);
            }
            let ttc = Math.round((base + optionValue) * 100) / 100;
            let ht = Math.round((ttc * self.prices.htRate) * 100) / 100;
            let commCourtier = Math.round(((ht / 100) * self.prices.commCourtier) * 100) / 100;
            let ttcWithSubscriptionCost =  Math.round((ttc  + self.getSubscriptionCost()) * 100) / 100;
            self.ttcTotal.html(ttc);
            self.ttcTotalReply.html(ttc);
            self.ttcTotalWithSubscriptionCost.html(ttcWithSubscriptionCost);
            self.feesCost.html(self.getSubscriptionCost());
            self.commCourtier.html(commCourtier);
        },
        freezeEffectiveDate: function () {
            let $jqDate = (new Date()).toISOString().split('T')[0];
            if ($jqDate !== 'undefined' && $jqDate !== undefined) {
                let newDate = $jqDate.split("-");
                let $jqDateEffect = this.effectiveDate;
                $jqDateEffect.flatpickr({
                    minDate: new Date(newDate[0], newDate[1] - 1, parseInt(newDate[2]) + 1),
                    allowInput: true,
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    disableMobile: true,
                    nextArrow: '<i class="zwicon-arrow-right" />',
                    prevArrow: '<i class="zwicon-arrow-left" />'
                });
            }
        },
        reloadDateReview: function () {
            let effectiveDate = this.effectiveDate[0];
            if (effectiveDate !== 'undefined' && effectiveDate !== undefined) {
                let newDate = effectiveDate.value.split("/");
                this.dateReview.html(newDate[0] + '/' + newDate[1]);
            }
        },
        getEmployeesCount: function () {
            let $employees = $('ul.jurifisc_ul_employee');
            let employees = 0;
            $employees.each(function () {
                employees = $(this).find('li').length - 1;
            });

            return employees;
        },

        getOptionsCount: function () {
            let $subscribers = $('ul.jurifisc_ul_subscriber');
            let subscribers = 0;
            $subscribers.each(function () {
                subscribers = $(this).find('li').length - 1;
            });
            return subscribers;
        },
        hideErrorEmployees: function () {
            this.errorsLegalEmployees.html('');
            this.errorsLegalEmployees.hide();
            this.errorsLegalEmployeesSubmit.html();
            this.errorsLegalEmployeesSubmit.hide();
        },
        hideErrorOptions: function () {
            this.errorsLegaOptions.html('');
            this.errorsLegaOptions.hide();
            this.errorsLegaOptionsSubmit.html('');
            this.errorsLegaOptionsSubmit.hide();
        },
        showErrorsEmployees: function (msg) {
            this.errorsLegalEmployees.html(msg);
            this.errorsLegalEmployees.show();
            this.errorsLegalEmployeesSubmit.html(msg);
            this.errorsLegalEmployeesSubmit.show();
        },
        showErrorsOptions: function (msg) {
            this.errorsLegaOptions.html(msg);
            this.errorsLegaOptions.show();
            this.errorsLegaOptionsSubmit.html(msg);
            this.errorsLegaOptionsSubmit.show();
        },
        checkForm: function () {
            let self = this;
            self.hideErrorEmployees();
            self.hideErrorOptions();
            let isValid = true;
            let inputNumberEmployees = parseInt(self.packSecondCount.val());
            let inputNumberSubscribers = parseInt(self.optionCount.val());
            let subscribers = self.getOptionsCount();
            let employees = self.getEmployeesCount();

            if (self.isPackSecond() && (employees === 0 || inputNumberEmployees === 0 || employees !== inputNumberEmployees)) {
                self.showErrorsEmployees('Veuillez compléter la liste des salariés')
                isValid = false;
            }

            if (self.hadOption() && (subscribers === 0 || inputNumberSubscribers === 0 || subscribers !== inputNumberSubscribers)) {
                self.showErrorsOptions('Veuillez compléter la liste des dirigeants')
                isValid = false;
            }

            return isValid;
        },
    }

    _legalContract.calculatePrime();
    _legalContract.packSecondCount.change(function () {
        _legalContract.reloadPackSecondTotal();
        _legalContract.calculatePrime();
    });

    _legalContract.packOne.change(function () {
        _legalContract.calculatePrime();
    });

    _legalContract.packSecond.change(function () {
        _legalContract.calculatePrime();
    });

    _legalContract.option.change(function () {
        _legalContract.calculatePrime();
    });

    _legalContract.optionCount.change(function () {
        _legalContract.reloadOptionTotal()
        _legalContract.calculatePrime();
    });

    _legalContract.freezeEffectiveDate();
    _legalContract.reloadDateReview();

    _legalContract.effectiveDate.change(function () {
        _legalContract.reloadDateReview();
    });
    _legalContract.subscriptionCost.change(function () {
        _legalContract.calculatePrime();
    });

    $("#form_jurifisc").on("submit", function (event) {
        if(_legalContract.checkForm() === false){
             return false;
        }
    });

});
