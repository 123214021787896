$(function () {
    "use strict";

    const loader = $('\n' +
            '<div class="loading">\n' +
            '    <div class="block-loading"><div class=" sk-chase">\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '        <div class="sk-chase-dot"></div>\n' +
            '    </div></div>\n' +
            '</div>');

    let customer = {
        selectLegalStatus: $('#customer_legalStatus'),
        inputAnnualRevenue: $('#customer_annualRevenue'),
        stateLegalStatus: function () {
            this.selectLegalStatus.on('change', function () {
                if (parseInt(this.value) === 8 && parseInt(customer.inputAnnualRevenue.val()) > 80000) {
                    $('#_customer-specific-status').modal();
                    customer.inputAnnualRevenue.val(0);
                }
            })

            this.inputAnnualRevenue.on('change', function () {
                if (parseInt(this.value) > 80000 && parseInt(customer.selectLegalStatus.val()) === 8) {
                    $('#_customer-specific-status').modal();
                    this.value = 0;
                }
            })
        },
        selectTypeCustomer: function() {
            $(document).on('change', '#select_type_customer', function (){
                if ($(this).val() === 'physical' || $(this).val() === 'corporation') {
                    $.ajax({
                        url: Routing.generate('customer_create_ajax'),
                        data: {typeCustomer:$(this).val(), product: $(this).attr('data-product')}
                    })
                        .done(function (data) {
                            $('#error_type_customer').html('');
                            $('#create_customer').html(data);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            $('#create_customer').html('');
                            $('#error_type_customer').html('<h5><span class="badge small badge-danger">Erreur</span> : ' + jqXHR.responseText + '</h5>');
                        });
                }
                else {
                    $('#create_customer').html('');
                    $('#error_type_customer').html('');
                }
            });
        },
        createCustomer: function() {
            $(document).on('submit', '#customer_form', function (e) {
                e.preventDefault();

                let $form = $(this).closest('form');

                $(this).append(loader.clone());
                $.ajax({
                    url : $form.attr('action') + '?typeCustomer=' + $('#select_type_customer').val(),
                    type: $form.attr('method'),
                    dataType: 'JSON',
                    data : new FormData(this),
                    processData: false,
                    contentType: false,
                })
                    .done(function (data, textStatus, jqXHR) {
                        if (jqXHR.status === 201) {
                            window.location.replace(data);
                            return false;
                        }

                        $('#error_type_customer').html('');
                        $('#create_customer').html(data);
                    })
                    .fail(function(jqXHR, textStatus, errorThrown) {
                        $('#create_customer').html('');
                        $('#error_type_customer').html('<h5><span class="badge small badge-danger">Erreur</span> ' + jqXHR.responseText + '</h5>');
                    });

                return false;
            });
        }
    };
    $('.my-modal').modal();
    customer.stateLegalStatus();
    customer.selectTypeCustomer();
    customer.createCustomer();
});

$(function () {
    "use strict";
    let customer = {

        formConfirationValidate: function () {
            $('#dataModalGFA').modal({backdrop: 'static', keyboard: false});
            $('#dataModalGFA').modal('show');
            // $('#__gfa-submit').click(function () {
            //     let response = $('#confirmationChek input:radio:checked').val();
            //     if (response === "yes") {
            //         window.location.href = $('#redirect-gfa-path').val();
            //     } else {
            //         $('#dataModalGFA').modal('hide');
            //     }
            // });
             $('#__gfa-submit').click(function () {
                    window.location.href = $('#redirect-gfa-path').val();
            });
        }
    };

    customer.formConfirationValidate();
});