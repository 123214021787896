$(function () {
    "use strict";

    // Get mrp activities checkbox
    let activitiesMrp = $("#underwriter_mrp_activities").attr('checked', true);

    // Block the choice of activities to 6
    activitiesMrp.click(function () {
        if (document.querySelectorAll('input[type="checkbox"]:checked').length > 6) {
            return false;
        }
    });

    let receivership = $('#underwriter_mrp_receivership');
    let schedule = $('#underwriter_mrp_schedule');
    let declaration = $('#underwriter_mrp_declaration');
    let desaffectedPlace = $('#underwriter_mrp_desaffectedPlace');
    let historicalBuilding = $('#underwriter_mrp_historicalBuilding');
    let neighborActivityBuilding = $('#underwriter_mrp_neighborActivityBuilding');
    let naturalDisaster = $('#underwriter_mrp_naturalDisaster');
    let autoDebit = $('#underwriter_mrp_autoDebit');
    let selectTerminateClaim = $('#underwriter_mrp_terminateClaim');

    selectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    receivership.click(function () {
        if (this.value === '1') {
            schedule.val(1);
        }
    });

    schedule.click(function () {
        if (this.value === '2' || this.value === '3') {
            receivership.val(0);
        }

        if (parseInt(this.value) === 4) {
            autoDebit.val(1);
        }
    });

    autoDebit.click(function () {
        if (parseInt(this.value) === 0 && parseInt(schedule.val()) === 4) {
            $('#__choose-schedule-monthly').modal();
            this.value = 1;
        }
    });

    declaration.click(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_subscription-prohibited').modal();
        }
    });

    desaffectedPlace.click(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_subscription-prohibited').modal();
        }
    });

    historicalBuilding.click(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_subscription-prohibited').modal();
        }
    });

    neighborActivityBuilding.click(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_subscription-prohibited').modal();
        }
    });

    naturalDisaster.click(function () {
        if (parseInt(this.value) === 1) {
            this.value = 0;
            $('#_subscription-prohibited').modal();
        }
    });

    let packRc = $('#underwriter_mrp_packType_1');
    let packPrivilege = $('#underwriter_mrp_packType_2');
    let packExcellence = $('#underwriter_mrp_packType_3');
    let operatingLoss = $('#underwriter_mrp_operatingLoss');
    let frozenWare = $('#underwriter_mrp_frozenWare');

    function selectRc() {
        operatingLoss.val(0).prop('disabled', true);
        frozenWare.val(0).prop('disabled', true);
    }

    function selectPrivilege() {
        operatingLoss.prop('disabled', false);
        frozenWare.prop('disabled', false);
    }

    function selectExcellence() {
        operatingLoss.prop('disabled', false);
        frozenWare.prop('disabled', false);
    }

    if (packRc.is(':checked')) {
        selectRc();
    }
    if (packPrivilege.is(':checked')) {
        selectPrivilege();
    }
    if (packExcellence.is(':checked')) {
        selectExcellence()
    }

    packRc.change(function () {
        if ($(this).is(':checked')) {
            selectRc();
        }
    });

    packPrivilege.change(function () {
        if ($(this).is(':checked')) {
            selectPrivilege();
        }
    });

    packExcellence.change(function () {
        if ($(this).is(':checked')) {
            selectExcellence()
        }
    });

    /**
     * @type {{selectReceivership: *, selectUnpaid: *, cantCreateUnderwriter: cantCreateUnderwriter, selectSchedule: *, selectNonHardMaterials: *}}
     */
    let underwriterMrp = {
        selectUnpaid: $('#underwriter_mrp_unpaid'),
        selectSchedule: $('#underwriter_mrp_schedule'),
        selectReceivership: $('#underwriter_mrp_receivership'),
        selectNonHardMaterials: $('#underwriter_mrp_nonHardMaterials'),
        selectInsuranceOwner: $('#underwriter_mrp_insuranceOwner'),
        inputLegalStatusOwner: $('#underwriter_mrp_legalStatusOwner'),
        rulesUnderwriter: function () {
            this.selectUnpaid.on('change', function () {
                if (parseInt(this.value) === 1) {
                    $('#__unpaid-mrp').modal();
                    underwriterMrp.selectSchedule.val(1);
                }
            });

            this.selectReceivership.on('change', function () {
                if (parseInt(this.value) === 1) {
                    $('#__receivership-mrp').modal();
                    underwriterMrp.selectSchedule.val(1);
                }
            });

            this.selectSchedule.on('change', function () {
                if (((parseInt(this.value) !== 1 && parseInt(this.value) !== 2) && parseInt(underwriterMrp.selectUnpaid.val()) === 1) || (parseInt(this.value) !== 1 && parseInt(underwriterMrp.selectReceivership.val()) === 1)) {
                    $('#__receivership-or-unpaid-mrp').modal();
                    this.value = 1;
                }
            });

            this.selectNonHardMaterials.on('change', function () {
                if (parseInt(this.value) === 0) {
                    $('#_subscription-prohibited').modal();
                    this.value = 1;
                }
            });

            if (parseInt(this.selectInsuranceOwner.val()) === 1) {
                underwriterMrp.inputLegalStatusOwner.prop('disabled', false);
            }

            this.selectInsuranceOwner.on('change', function () {
                if (parseInt(this.value) === 1) {
                    underwriterMrp.inputLegalStatusOwner.prop('disabled', false);
                    return;
                }

                underwriterMrp.inputLegalStatusOwner.prop('disabled', true).val('');
            })
        }
    };

    underwriterMrp.rulesUnderwriter();
});