import {actions} from "./_actions_by_activities";

let tableClass = $('.activities-table');
let formObj = tableClass.closest('form');

if (tableClass[0]) {
    let table = tableClass.DataTable({
        lengthMenu: [[5, 10, 20, 45, -1], ["5 lignes", "10 lignes", "20 lignes", "45 lignes", "Tout"]],
        "language": {
            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
        },
        'ordering': true,
        columnDefs: [{
            orderable: false,
            targets: "no-sort"
        }],
        sDom: '<"dataTables__top"flB>rt<"dataTables__bottom"ip><"clear">',
    });

    actions.updateFranchise(table.$('input'), true);
    actions.checkMaxActivities(table.$('input'), true);
    $('.activities-table > tbody > tr').click(function (event) {
        $(':checkbox', this).prop("checked", !$(':checkbox', this).prop("checked"));
        actions.updateFranchise(table.$('input'), false);
        actions.checkMaxActivities(table.$('input'), false, this);
        event.preventDefault();
    });

    formObj.submit(function () {
        let data = table.$('input').serializeArray();
        $.each(data, function (index, value) {
            $('<input />').attr("type", "hidden")
                .attr('name', value.name)
                .attr('value', value.value)
                .appendTo(formObj);
        });

        return true;
    });

}
