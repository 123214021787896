$(function () {
    "use strict";
    let letterOfIntentGfa = {
        formDoChange: function () {
            let doSelect = $('.do_select');
            if (doSelect == undefined) {
                return;
            }

            doSelect.on('change', function () {
                if (doSelect.val() == '0') {
                    $('#dataModal').modal({backdrop: 'static', keyboard: false});
                    $('#dataModal').modal('show');
                }
            })
        }
    };

    letterOfIntentGfa.formDoChange();
});