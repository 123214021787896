$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let selectTechnicalController = $('#survey_technicalControllerPresence');
    let nameTechnicalController = $('#survey_technicalControllerName');
    let technicalControllerNameField = $('._technicalControllerNameField');
    let technicalControllerTypeField = $('._technicalControllerTypeField');

    let selectProjectOwnerInvolved = $('#survey_projectManagementProjectOwnerInvolved');
    let worksProjectOwner = $('#survey_projectManagementProjectOwnerWorks');
    let qualificationProjectOwner = $('#survey_projectManagementProjectOwnerQualification')
    let projectOwnerWorksField = $('._projectOwnerWorksField');
    let projectOwnerQualificationField = $('._projectOwnerQualificationField');

    let horizontalRange = $('#survey_horizontalRange');
    let peatSiltClayHighWaterTable = $('#survey_peatSiltClayHighWaterTable');
    let strongOrMediumExpansivenessLand = $('#survey_strongOrMediumExpansivenessLand');

    let dateStartingWork= $('#underwriter_startingWork');
    let dateExpectedStartingWork = $('#survey_expectedStartingWork');

    /** Technical controller part **/

    function setFormHidden() {
        nameTechnicalController.val("");
        technicalControllerNameField.hide();
        technicalControllerTypeField.hide();
    }

    selectTechnicalController.change(function () {
        if (parseInt(this.value) === 1) {
            technicalControllerNameField.show();
            technicalControllerTypeField.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(selectTechnicalController.val()) === 1) {
        technicalControllerNameField.show();
        technicalControllerTypeField.show();
    } else {
        setFormHidden();
    }

    /** Project owner part **/

    function setFormHiddenProjectOwner() {
        worksProjectOwner.val("");
        technicalControllerNameField.val("");
        projectOwnerWorksField.hide();
        projectOwnerQualificationField.hide();
    }

    selectProjectOwnerInvolved.change(function () {
        if (parseInt(this.value) === 1) {
            projectOwnerWorksField.show();
            projectOwnerQualificationField.show();
            return;
        }
        setFormHiddenProjectOwner();
    });

    if (parseInt(selectProjectOwnerInvolved.val()) === 1) {
        projectOwnerWorksField.show();
        projectOwnerQualificationField.show();
    } else {
        setFormHiddenProjectOwner();
    }

    horizontalRange.change(function () {
        if (this.value > 0) {
            $('#_subscription-prohibited').modal();
            horizontalRange.val(0);
        }
    });

    peatSiltClayHighWaterTable.change(function () {
        if (this.value > 0) {
            $('#_soilStudyPeatSiltClayHighWaterTable').modal();
        }
    });

    strongOrMediumExpansivenessLand.change(function () {
        if (this.value > 0 ) {
            $('#_soilStudy').modal();
        }
    });


    dateExpectedStartingWork.change(function () {
        if (dateStartingWork.val() > dateExpectedStartingWork.val()) {
            dateExpectedStartingWork.val(dateStartingWork.val())
            $('#_date_expectedWord_prohibited').modal();
        }
    });

});