$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMbbRcdStructorPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMbbRcdStructorSelectInsured             = $('#amendment_ax_mbb_rcd_structor_insured');
    let amendmentAxMbbRcdStructorSelectHasContract         = $('#amendment_ax_mbb_rcd_structor_hasContract');
    let amendmentAxMbbRcdStructorSelectRcproInsured        = $('#amendment_ax_mbb_rcd_structor_insuredRcpro');
    let amendmentAxMbbRcdStructorInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMbbRcdStructorSelectInsuredLastTwoYears = $('#amendment_ax_mbb_rcd_structor_insuredLastTwoYears');
    let amendmentAxMbbRcdStructorInputNumberDisaster       = $('#amendment_ax_mbb_rcd_structor_numberDisaster');
    let amendmentAxMbbRcdStructorInputAmountDisaster       = $('#amendment_ax_mbb_rcd_structor_amountDisaster');
    let amendmentAxMbbRcdStructorDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMbbRcdStructorDateTermination           = $('#amendment_ax_mbb_rcd_structor_dateTermination');
    let amendmentAxMbbRcdStructorSelectRcdFalseDeclaration = $('#amendment_ax_mbb_rcd_structor_declaration');
    let amendmentAxMbbRcdStructorSelectUnpaid              = $('#amendment_ax_mbb_rcd_structor_unpaid');
    let amendmentAxMbbRcdStructorSelectTerminateClaim      = $('#amendment_ax_mbb_rcd_structor_terminateClaim');
    let amendmentAxMbbRcdStructorSelectResumptionLiability = $('#amendment_ax_mbb_rcd_structor_resumptionLiability');
    let amendmentAxMbbRcdStructorSelectBoxOutsourcing      = $('#amendment_ax_mbb_rcd_structor_outsourcing');
    let amendmentAxMbbRcdStructorSelectReceivership        = $('#amendment_ax_mbb_rcd_structor_receivership');
    let amendmentAxMbbRcdStructorSelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMbbRcdStructorSchedule                  = $('#amendment_ax_mbb_rcd_structor_schedule');
    let amendmentAxMbbRcdStructorAutoDebit                 = $('#amendment_ax_mbb_rcd_structor_autoDebit');

    function setFormHidden() {
        amendmentAxMbbRcdStructorSelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorSelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorDateTermination.val("");
        amendmentAxMbbRcdStructorInsuredLastTwoYears.hide();
        amendmentAxMbbRcdStructorSelectInsuredLastTwoYears.val(1);
        amendmentAxMbbRcdStructorInputNumberDisaster.val(0);
        amendmentAxMbbRcdStructorInputAmountDisaster.val(0);
        amendmentAxMbbRcdStructorSelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorSelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorSelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdStructorPassiveGroup.hide();
    }

    amendmentAxMbbRcdStructorSelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdStructorPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMbbRcdStructorSelectInsured.val()) === 1) {
        amendmentAxMbbRcdStructorPassiveGroup.show();
    }
    if (parseInt(amendmentAxMbbRcdStructorSelectHasContract.val()) === 1) {
        amendmentAxMbbRcdStructorDateTerminationBlock.hide();
        amendmentAxMbbRcdStructorDateTermination.val("");
        amendmentAxMbbRcdStructorInsuredLastTwoYears.show();
    }

    amendmentAxMbbRcdStructorSelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdStructorDateTerminationBlock.hide();
            amendmentAxMbbRcdStructorDateTermination.val("");
            amendmentAxMbbRcdStructorInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMbbRcdStructorInsuredLastTwoYears.hide();
        amendmentAxMbbRcdStructorSelectInsuredLastTwoYears.val(0);
        amendmentAxMbbRcdStructorDateTerminationBlock.show();
    });

    amendmentAxMbbRcdStructorSelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMbbRcdStructorSelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorSelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorSchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMbbRcdStructorAutoDebit.val(1);
        }
    });

    amendmentAxMbbRcdStructorInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorSelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorSelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMbbRcdStructorSelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdStructorSelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMbbRcdStructorSelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});