// Import scss
import '../scss/app.scss';

// Node_modules
import "@popperjs/core";
import "@fullcalendar/core";
import "@fullcalendar/daygrid";
import "flatpickr";
import "bootstrap-colorpicker";
import "trumbowyg";
import "jqtree";
import "datatables.net";
import "overlayscrollbars/js/jquery.overlayScrollbars";
import "jquery.flot";
import "jquery.flot/jquery.flot.pie";
import "jquery-mask-plugin";
import "bootstrap-notify";
import "jquery-ui/ui/widgets/autocomplete";
import "select2/dist/js/select2.min"

// Bootstrap
import "bootstrap";

// Custom Yuna js
import "./_inc";

import "./libs/fmelfinder/_fmelfinder";