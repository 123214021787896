let $collectionHolder = $('ul.__intervenants');

$collectionHolder.each(function() {
    var that = $(this);
    let $addButton = $('<button type="button" class="btn btn-theme-dark btn--icon-text __add-intervenants"><i class="zwicon-plus"></i>Ajouter</button>');
    var $newLinkLi = $('<li class="list-group-item"></li>').append($addButton);

    that.find('li').each(function () {
        addFormDeleteLink($(this));
    });

    that.append($newLinkLi);

    $addButton.on('click', function (event) {
        addTagForm(that, $newLinkLi, event);
    });
});

function addTagForm($collectionHolder, $newLinkLi, event) {
    let prototype = $collectionHolder.data('prototype');
    let index = $collectionHolder.find('div.row').length;

    let valueIndex = index -1;
    let elements = [0, 1, 2, 3, 4];

    let ulElement = event.currentTarget.parentNode.parentNode;
    let count ;

    // if the batch includes at least one participant
    if (ulElement.querySelector('li > div')) {
        // on récupère cette ligne pour lui remettre le bon index avec la méthode rewriteRow
        let row = ulElement.querySelector('li > div').parentNode;
        count = 0;
        rewriteRow(row, count);

        // we loop to rewrite the correct indexes for each additional line corresponding to a participant
        while (typeof row.nextElementSibling.querySelector('div') !== 'undefined' && row.nextElementSibling.querySelector('div') !== null) {
            row = row.nextElementSibling;
            count++;
            rewriteRow(row, count);
        }
    }

    function rewriteRow(row, index) {
        let divParentEl = row.querySelector('div');

        let element = divParentEl.querySelector('div');
        rewriteElementsOfRow(element, index);

        // we loop on each element of the row to modify its id and name with the correct index
        while (typeof element.nextElementSibling.querySelector('input') !== 'undefined' && element.nextElementSibling.querySelector('input') !== null) {
            element = element.nextElementSibling;
            rewriteElementsOfRow(element, index);
        }
    }

    function rewriteElementsOfRow (element, index) {
        if(ulElement.getAttribute("class").indexOf('jurifisc')!== -1){
            console.log('jurifisc');
            return;
        }
        let inputElement = element.querySelector('input');
        let elementId = inputElement.id;
        let elements = elementId.split("_");

        // the variable oldNumber corresponds to the old index. We store it to be able to use it in the replace method to modify the name
        let oldNumber = elements[2].toString();

        elements[2] = index.toString();
        let elementNewId = elements.join("_");
        // we rewrite the id with the correct index
        inputElement.id = elementNewId;

        let elementName = inputElement.name;
        let elementNewName = elementName.replace(oldNumber, index.toString());
        // we rewrite the name with the correct index
        inputElement.name = elementNewName;
    }

    let newForm = prototype;

    if (ulElement.getAttribute("class").indexOf('jurifisc')!== -1 || index < 5) {
        newForm = newForm.replace(/__name__/g, index);
        $collectionHolder.data('index', index + 1);
        let $newFormLi = $('<li class="list-group-item"></li>').append(newForm);
        $newLinkLi.before($newFormLi);

        addFormDeleteLink($newFormLi);
    }
}

function addFormDeleteLink($formLi) {
    var $removeFormButton = $('<div class="col-md-1" ><button type="button" class="btn btn-theme-dark btn--icon ml-2"><i class="zwicon-trash"></i></button></div>');
    $formLi.find('input').addClass('form-control d-inline col-12');
    $formLi.find('.row').append($removeFormButton);

    $removeFormButton.on('click', function () {
        $formLi.remove();
    });
}
