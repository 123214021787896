$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMbbRcdPibPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMbbRcdPibSelectInsured             = $('#amendment_ax_mbb_rcd_pib_insured');
    let amendmentAxMbbRcdPibSelectHasContract         = $('#amendment_ax_mbb_rcd_pib_hasContract');
    let amendmentAxMbbRcdPibSelectRcproInsured        = $('#amendment_ax_mbb_rcd_pib_insuredRcpro');
    let amendmentAxMbbRcdPibInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMbbRcdPibSelectInsuredLastTwoYears = $('#amendment_ax_mbb_rcd_pib_insuredLastTwoYears');
    let amendmentAxMbbRcdPibInputNumberDisaster       = $('#amendment_ax_mbb_rcd_pib_numberDisaster');
    let amendmentAxMbbRcdPibInputAmountDisaster       = $('#amendment_ax_mbb_rcd_pib_amountDisaster');
    let amendmentAxMbbRcdPibDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMbbRcdPibDateTermination           = $('#amendment_ax_mbb_rcd_pib_dateTermination');
    let amendmentAxMbbRcdPibSelectRcdFalseDeclaration = $('#amendment_ax_mbb_rcd_pib_declaration');
    let amendmentAxMbbRcdPibSelectUnpaid              = $('#amendment_ax_mbb_rcd_pib_unpaid');
    let amendmentAxMbbRcdPibSelectTerminateClaim      = $('#amendment_ax_mbb_rcd_pib_terminateClaim');
    let amendmentAxMbbRcdPibSelectResumptionLiability = $('#amendment_ax_mbb_rcd_pib_resumptionLiability');
    let amendmentAxMbbRcdPibSelectBoxOutsourcing      = $('#amendment_ax_mbb_rcd_pib_outsourcing');
    let amendmentAxMbbRcdPibSelectReceivership        = $('#amendment_ax_mbb_rcd_pib_receivership');
    let amendmentAxMbbRcdPibSelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMbbRcdPibSchedule                  = $('#amendment_ax_mbb_rcd_pib_schedule');
    let amendmentAxMbbRcdPibAutoDebit                 = $('#amendment_ax_mbb_rcd_pib_autoDebit');

    function setFormHidden() {
        amendmentAxMbbRcdPibSelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibSelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibDateTermination.val("");
        amendmentAxMbbRcdPibInsuredLastTwoYears.hide();
        amendmentAxMbbRcdPibSelectInsuredLastTwoYears.val(1);
        amendmentAxMbbRcdPibInputNumberDisaster.val(0);
        amendmentAxMbbRcdPibInputAmountDisaster.val(0);
        amendmentAxMbbRcdPibSelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibSelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibSelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibSelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMbbRcdPibPassiveGroup.hide();
    }

    amendmentAxMbbRcdPibSelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdPibPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMbbRcdPibSelectInsured.val()) === 1) {
        amendmentAxMbbRcdPibPassiveGroup.show();
    }

    if (parseInt(amendmentAxMbbRcdPibSelectHasContract.val()) === 1) {
        amendmentAxMbbRcdPibDateTerminationBlock.hide();
        amendmentAxMbbRcdPibDateTermination.val("");
        amendmentAxMbbRcdPibInsuredLastTwoYears.show();
    }

    amendmentAxMbbRcdPibSelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMbbRcdPibDateTerminationBlock.hide();
            amendmentAxMbbRcdPibDateTermination.val("");
            amendmentAxMbbRcdPibInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMbbRcdPibInsuredLastTwoYears.hide();
        amendmentAxMbbRcdPibSelectInsuredLastTwoYears.val(0);
        amendmentAxMbbRcdPibDateTerminationBlock.show();
    });

    amendmentAxMbbRcdPibSelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMbbRcdPibSelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdPibSelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdPibSchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMbbRcdPibAutoDebit.val(1);
        }
    });

    amendmentAxMbbRcdPibInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdPibSelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
            return false;
        }
    });

    amendmentAxMbbRcdPibSelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMbbRcdPibSelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMbbRcdPibInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMbbRcdPibSelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMbbRcdPibSelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});