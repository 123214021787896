$(document).ready(function (){
    const gedSpin = $('<div style="position:relative;top: 50%;transform: translateY(-50%);"><div class="looping-rhombuses-spinner"><div class="rhombus"></div><div class="rhombus"></div><div class="rhombus"></div></div></div>');
    let elfinder = $('.__elfinder');
    let scriptLink = $('<script>', {'src': '../../build/fmelfinder/js/_override_config.js', 'type' : 'text/javascript'});

    elfinder.each(function () {
        let elfinder = $(this);
        elfinder.hide().after(gedSpin.clone());

        setTimeout(function () {
            elfinder.contents().find('head').append(scriptLink.clone());
            elfinder.fadeIn('slow', 'swing');
            elfinder.siblings(gedSpin).remove();
        }, 4000);
    });
})