$(document).ready(function () {
    /**
     * @type {jQuery|HTMLElement}
     * customer have not a contract, display dateTermination
     */
    let amendmentAxMicRcdEasyPassiveGroup              = $('._underwriter-rcd-lu-insured');
    let amendmentAxMicRcdEasySelectInsured             = $('#amendment_ax_mic_rcd_easy_insured');
    let amendmentAxMicRcdEasySelectHasContract         = $('#amendment_ax_mic_rcd_easy_hasContract');
    let amendmentAxMicRcdEasySelectRcproInsured        = $('#amendment_ax_mic_rcd_easy_insuredRcpro');
    let amendmentAxMicRcdEasyInsuredLastTwoYears       = $('._underwriter-rcd-lu-insured-last-years');
    let amendmentAxMicRcdEasySelectInsuredLastTwoYears = $('#amendment_ax_mic_rcd_easy_insuredLastTwoYears');
    let amendmentAxMicRcdEasyInputNumberDisaster       = $('#amendment_ax_mic_rcd_easy_numberDisaster');
    let amendmentAxMicRcdEasyInputAmountDisaster       = $('#amendment_ax_mic_rcd_easy_amountDisaster');
    let amendmentAxMicRcdEasyDateTerminationBlock      = $('._underwriter-rcd-lu-insured-date-termination');
    let amendmentAxMicRcdEasyDateTermination           = $('#amendment_ax_mic_rcd_easy_dateTermination');
    let amendmentAxMicRcdEasySelectRcdFalseDeclaration = $('#amendment_ax_mic_rcd_easy_declaration');
    let amendmentAxMicRcdEasySelectUnpaid              = $('#amendment_ax_mic_rcd_easy_unpaid');
    let amendmentAxMicRcdEasySelectTerminateClaim      = $('#amendment_ax_mic_rcd_easy_terminateClaim');
    let amendmentAxMicRcdEasySelectResumptionLiability = $('#amendment_ax_mic_rcd_easy_resumptionLiability');
    let amendmentAxMicRcdEasySelectBoxOutsourcing      = $('#amendment_ax_mic_rcd_easy_outsourcing');
    let amendmentAxMicRcdEasySelectReceivership        = $('#amendment_ax_mic_rcd_easy_receivership');
    let amendmentAxMicRcdEasySelectInsuredRcpro        = $('#underwriter_insuredRcpro');
    let amendmentAxMicRcdEasySchedule                  = $('#amendment_ax_mic_rcd_easy_schedule');
    let amendmentAxMicRcdEasyAutoDebit                 = $('#amendment_ax_mic_rcd_easy_autoDebit');

    function setFormHidden() {
        amendmentAxMicRcdEasySelectHasContract.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasySelectRcproInsured.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasyDateTermination.val("");
        amendmentAxMicRcdEasyInsuredLastTwoYears.hide();
        amendmentAxMicRcdEasySelectInsuredLastTwoYears.val(1);
        amendmentAxMicRcdEasyInputNumberDisaster.val(0);
        amendmentAxMicRcdEasyInputAmountDisaster.val(0);
        amendmentAxMicRcdEasySelectUnpaid.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasySelectRcdFalseDeclaration.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasySelectResumptionLiability.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasySelectInsuredRcpro.val(0).prop('selected', 'selected');
        amendmentAxMicRcdEasyPassiveGroup.hide();
    }

    amendmentAxMicRcdEasySelectInsured.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdEasyPassiveGroup.show();
            return;
        }
        setFormHidden();
    });

    if (parseInt(amendmentAxMicRcdEasySelectInsured.val()) === 1) {
        amendmentAxMicRcdEasyPassiveGroup.show();
    }

    if (parseInt(amendmentAxMicRcdEasySelectHasContract.val()) === 1) {
        amendmentAxMicRcdEasyDateTerminationBlock.hide();
        amendmentAxMicRcdEasyDateTermination.val("");
        amendmentAxMicRcdEasyInsuredLastTwoYears.show();
    }

    amendmentAxMicRcdEasySelectHasContract.change(function () {
        if (parseInt(this.value) === 1) {
            amendmentAxMicRcdEasyDateTerminationBlock.hide();
            amendmentAxMicRcdEasyDateTermination.val("");
            amendmentAxMicRcdEasyInsuredLastTwoYears.show();
            return;
        }
        amendmentAxMicRcdEasyInsuredLastTwoYears.hide();
        amendmentAxMicRcdEasySelectInsuredLastTwoYears.val(0);
        amendmentAxMicRcdEasyDateTerminationBlock.show();
    });

    amendmentAxMicRcdEasySelectResumptionLiability.change(function () {
        if (parseInt(this.value) === 1 && parseInt(amendmentAxMicRcdEasySelectUnpaid.val()) === 1) {
            $('#_resumption-liability').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdEasySelectRcdFalseDeclaration.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_false-declaration').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdEasySchedule.change(function () {
        if (parseInt(this.value) === 4) {
            amendmentAxMicRcdEasyAutoDebit.val(1);
        }
    });

    amendmentAxMicRcdEasyInputAmountDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdEasySelectBoxOutsourcing.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_outsourcing').modal('show');
            this.value = 0;
            return false;
        }
    });

    amendmentAxMicRcdEasySelectReceivership.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule-receivership').modal('show');
        }
    });

    amendmentAxMicRcdEasySelectTerminateClaim.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_terminate-claim').modal('show');
            this.value = 0;
        }
    });

    amendmentAxMicRcdEasyInputNumberDisaster.change(function () {
        if (this.value === '') {
            this.value = 0;
        }
    });

    amendmentAxMicRcdEasySelectUnpaid.change(function () {
        if (parseInt(this.value) === 1) {
            $('#_schedule').modal('show');
            amendmentAxMicRcdEasySelectResumptionLiability.val(0).prop('selected', 'selected');
        }
    });
});