$(document).ready(function () {

    let underwriterQuickRcd = {
        formQuick: $('#__form_quick'),
        quickRcdBuildingAmountDisaster: $('.__quick_rcd_building_amount_disaster'),
        quickRcdBatizAmountDisaster: $('.__quick_rcd_batiz_amount_disaster'),
        quickRcdVsbPibAmountDisaster: $('.__quick_rcd_vsb_pib_amount_disaster'),
        quickRcdMicPibAmountDisaster: $('.__quick_rcd_mic_pib_amount_disaster'),
        quickRcdMbbPibAmountDisaster: $('.__quick_rcd_mbb_pib_amount_disaster'),
        quickRcdFabricantAmountDisaster: $('.__quick_rcd_fabricant_amount_disaster'),
        quickRcdConstructorAmountDisaster: $('.__quick_rcd_constructor_amount_disaster'),
        quickRcdStructorAmountDisaster: $('.__quick_rcd_structor_amount_disaster'),
        quickRcdSereniteAmountDisaster: $('.__quick_rcd_serenite_amount_disaster'),
        quickRcdEasyAmountDisaster: $('.__quick_rcd_easy_amount_disaster'),
        quickRcdPoolAmountDisaster: $('.__quick_rcd_pool_amount_disaster'),
        quickRcdBuildingAnnualRevenue: $('#underwriter_quick_rcd_building_annualRevenue'),
        quickRcdBatizAnnualRevenue: $('#underwriter_quick_rcd_batiz_annualRevenue'),
        quickRcdVsbPibAnnualRevenue: $('#underwriter_quick_rcd_vsb_pib_annualRevenue'),
        quickRcdMicPibAnnualRevenue: $('#underwriter_quick_rcd_mic_pib_annualRevenue'),
        quickRcdMbbPibAnnualRevenue: $('#underwriter_quick_rcd_mbb_pib_annualRevenue'),
        quickRcdFabricantAnnualRevenue: $('#underwriter_quick_rcd_fabricant_annualRevenue'),
        quickRcdConstructorAnnualRevenue: $('#underwriter_quick_rcd_constructor_annualRevenue'),
        quickRcdStructorAnnualRevenue: $('#underwriter_quick_rcd_structor_annualRevenue'),
        quickRcdSereniteAnnualRevenue: $('#underwriter_quick_rcd_serenite_annualRevenue'),
        quickRcdEasyAnnualRevenue: $('#underwriter_quick_rcd_easy_annualRevenue'),
        quickRcdPoolAnnualRevenue: $('#underwriter_quick_rcd_pool_annualRevenue'),
        quickRcdPoolAnnualRevenueRc: $('#underwriter_quick_rcd_pool_annualRevenue_rc'),
        quickPriceTtc: $('#__quick_price_ttc'),
        quickCalculation: $('.__underwriter_quick_calculation'),
        url: window.location.pathname.split("/"),
        companyName: window.location.pathname.split("/")[4],
        typeProduct: window.location.pathname.split("/")[5],
        mbbPoolPackA: $('#__packAQuick'),
        mbbPoolPackB: $('#__packBQuick'),
        mbbPoolPack: $('#underwriter_quick_rcd_pool_pack'),
        table: $('.activities-table'),
        quickRcdCalculation: function (companyName, typeProduct) {
            $.ajax({
                url: '/' + companyName + '/' + typeProduct + '/new/quickRcd',
                type: 'POST',
                dataType: 'json',
                async: false,
                data: underwriterQuickRcd.quickCalculation.serialize(),

                success: function (data) {
                    $('#__quick_price_ttc').html(data.underwriterRcdQuickAmount.toFixed(2) + " € TTC");
                },
                error: function () {
                    $('#__quick_price_ttc').html('0 €');
                    return false;
                }
            });
        },
        clickQuickRcdCalculation: function () {
            underwriterQuickRcd.quickCalculation.click(function (e) {
                e.preventDefault();
                $( ".__selected" ).remove();
                if (underwriterQuickRcd.typeProduct === 'pool') {

                    // Get active table
                    let dataTable = underwriterQuickRcd.mbbPoolPackA.find('table').DataTable().$('input').serializeArray();
                    if (underwriterQuickRcd.mbbPoolPack.val() === '1') {
                        dataTable = underwriterQuickRcd.mbbPoolPackB.find('table').DataTable().$('input').serializeArray();
                    }

                    $.each(dataTable, function (index, value) {
                        $('<input />', {'class': '__selected'}).attr("type", "hidden")
                            .attr('name', value.name)
                            .attr('value', value.value)
                            .appendTo(underwriterQuickRcd.quickCalculation);
                    });

                    if (dataTable.length !== 0 && dataTable.length <= 12) {
                        underwriterQuickRcd.quickRcdCalculation(underwriterQuickRcd.companyName, underwriterQuickRcd.typeProduct)
                    }
                    if (underwriterQuickRcd.length === 0) {
                        $('#__quick_price_ttc').html('0 €');
                    }
                } else if (underwriterQuickRcd.typeProduct !== 'fabricant') {
                    let dataTable = underwriterQuickRcd.table.DataTable().$('input').serializeArray();
                    $.each(dataTable, function (index, value) {
                        $('<input />', {'class': '__selected'}).attr("type", "hidden")
                            .attr('name', value.name)
                            .attr('value', value.value)
                            .appendTo(underwriterQuickRcd.quickCalculation);
                    });

                    if (dataTable.length !== 0 && dataTable.length <= 12) {
                        underwriterQuickRcd.quickRcdCalculation(underwriterQuickRcd.companyName, underwriterQuickRcd.typeProduct)
                    }
                    if (underwriterQuickRcd.length === 0) {
                        $('#__quick_price_ttc').html('0 €');
                    }
                } else {
                    underwriterQuickRcd.quickRcdCalculation(underwriterQuickRcd.companyName, underwriterQuickRcd.typeProduct)
                }
            })
        },
        changeQuickRcdDisaster: function () {
            $('.__quick_rcd_disaster').change(function () {
                if (parseInt(this.value) === 1) {
                    underwriterQuickRcd.quickRcdBuildingAmountDisaster.show();
                    underwriterQuickRcd.quickRcdBatizAmountDisaster.show();
                    underwriterQuickRcd.quickRcdVsbPibAmountDisaster.show();
                    underwriterQuickRcd.quickRcdMicPibAmountDisaster.show();
                    underwriterQuickRcd.quickRcdMbbPibAmountDisaster.show();
                    underwriterQuickRcd.quickRcdFabricantAmountDisaster.show();
                    underwriterQuickRcd.quickRcdConstructorAmountDisaster.show();
                    underwriterQuickRcd.quickRcdStructorAmountDisaster.show();
                    underwriterQuickRcd.quickRcdSereniteAmountDisaster.show();
                    underwriterQuickRcd.quickRcdEasyAmountDisaster.show();
                    underwriterQuickRcd.quickRcdPoolAmountDisaster.show();
                }
                if (parseInt(this.value) === 0) {
                    underwriterQuickRcd.quickRcdBuildingAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdBatizAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdVsbPibAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdMicPibAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdMbbPibAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdFabricantAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdConstructorAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdStructorAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdSereniteAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdEasyAmountDisaster.hide();
                    underwriterQuickRcd.quickRcdPoolAmountDisaster.hide();
                }
            });
        },
        changeQuickRcdBuildingAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdBuildingAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdBuildingAnnualRevenue.val()) < 500000 || parseInt(underwriterQuickRcd.quickRcdBuildingAnnualRevenue.val()) > 5000000) {
                    underwriterQuickRcd.quickRcdBuildingAnnualRevenue.val(500000);
                    $('#__alert_quick_building_annual_revenue').modal('show');
                }
            });
        },
        changeQuickRcdBatizAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdBatizAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdBatizAnnualRevenue.val()) > 500000) {
                    underwriterQuickRcd.quickRcdBatizAnnualRevenue.val(0);
                    $('#__alert_quick_batiz_annual_revenue').modal('show');
                }
            });
        },
        changeQuickRcdVsbPibAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdVsbPibAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdVsbPibAnnualRevenue.val()) > 5000000) {
                    underwriterQuickRcd.quickRcdVsbPibAnnualRevenue.val(0);
                    $('#__alert_quick_vsb_pib_annual_revenue').modal('show');
                }
            });
        },
        changeQuickRcdMicPibAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdMicPibAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdMicPibAnnualRevenue.val()) > 1000000) {
                    underwriterQuickRcd.quickRcdMicPibAnnualRevenue.val(0);
                    $('#__alert_quick_mic_pib_annual_revenue').modal('show');
                }
            });
        },
        changeQuickRcdMbbPibAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdMbbPibAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdMbbPibAnnualRevenue.val()) > 1000000) {
                    underwriterQuickRcd.quickRcdMbbPibAnnualRevenue.val(0);
                    $('#__alert_quick_mbb_pib_annual_revenue').modal('show');
                }
            });
        },
        changeQuickRcdFabricantAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdFabricantAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdFabricantAnnualRevenue.val()) > 5000000) {
                    underwriterQuickRcd.quickRcdFabricantAnnualRevenue.val(0);
                    $('#__alert_quick_fabricant_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdConstructorAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdConstructorAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdConstructorAnnualRevenue.val()) > 2100000) {
                    underwriterQuickRcd.quickRcdConstructorAnnualRevenue.val(0);
                    $('#__alert_quick_constructor_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdStructorAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdStructorAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdStructorAnnualRevenue.val()) > 2100000) {
                    underwriterQuickRcd.quickRcdStructorAnnualRevenue.val(0);
                    $('#__alert_quick_structor_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdSereniteAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdSereniteAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdSereniteAnnualRevenue.val()) > 750000) {
                    underwriterQuickRcd.quickRcdSereniteAnnualRevenue.val(0);
                    $('#__alert_quick_serenite_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdEasyAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdEasyAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdEasyAnnualRevenue.val()) > 500000) {
                    underwriterQuickRcd.quickRcdEasyAnnualRevenue.val(0);
                    $('#__alert_quick_easy_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdPoolAnnualRevenue: function () {
            underwriterQuickRcd.quickRcdPoolAnnualRevenue.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdPoolAnnualRevenue.val()) > 2100000) {
                    underwriterQuickRcd.quickRcdPoolAnnualRevenue.val(0);
                    $('#__alert_quick_pool_annual_revenue').modal('show');
                }
            })
        },
        changeQuickRcdPoolAnnualRevenueRc: function () {
            underwriterQuickRcd.quickRcdPoolAnnualRevenueRc.change(function () {
                if (parseInt(underwriterQuickRcd.quickRcdPoolAnnualRevenueRc.val()) > 10000000) {
                    underwriterQuickRcd.quickRcdPoolAnnualRevenueRc.val(0);
                    $('#__alert_quick_pool_annual_revenue_rc').modal('show');
                }
            })
        },
        changeFormQuick: function () {
            underwriterQuickRcd.quickCalculation.change(function () {
                let dataTable = underwriterQuickRcd.table.DataTable().$('input').serializeArray();

                if (dataTable.length === 0 || dataTable.length > 12) {
                    $('#__alert_quick_activities').modal('show');
                    underwriterQuickRcd.quickPriceTtc.html('0 €');
                }
            })
        },
        initQuickPoolActivities: function () {
            // colappse for pack activity pool
            let packA = underwriterQuickRcd.mbbPoolPackA;
            let packB = underwriterQuickRcd.mbbPoolPackB;

            packA.hide();
            packB.hide();

            let packValue = underwriterQuickRcd.mbbPoolPack.val();
            if (packValue === '0' || packValue === '') {
                packA.show();
                packA.collapse('show');
            }

            if (packValue === '1') {
                packB.show();
            }
        },
        toggleQuickPoolActivities: function () {
            $('.toggle-input').click(function () {
                let packValue = underwriterQuickRcd.mbbPoolPack.val($(this).attr('data-value'));
                let packA = underwriterQuickRcd.mbbPoolPackA;
                let packB = underwriterQuickRcd.mbbPoolPackB;
                if (packValue.val() === '0') {
                    packB.collapse('hide');
                    $("#__packBQuick input").prop('checked', false);
                    packA.collapse('show');
                    packA.show();
                }

                if (packValue.val() === '1') {
                    packA.collapse('hide');
                    $("#__packAQuick input").prop('checked', false);
                    packB.collapse('show');
                    packB.show();
                }
            });
        }
    };

    // Quick calculation Rcd
    underwriterQuickRcd.quickPriceTtc.html('0 €');
    underwriterQuickRcd.clickQuickRcdCalculation();

    underwriterQuickRcd.quickRcdBuildingAmountDisaster.hide();
    underwriterQuickRcd.quickRcdBatizAmountDisaster.hide();
    underwriterQuickRcd.quickRcdVsbPibAmountDisaster.hide();
    underwriterQuickRcd.quickRcdMicPibAmountDisaster.hide();
    underwriterQuickRcd.quickRcdMbbPibAmountDisaster.hide();
    underwriterQuickRcd.quickRcdFabricantAmountDisaster.hide();
    underwriterQuickRcd.quickRcdConstructorAmountDisaster.hide();
    underwriterQuickRcd.quickRcdStructorAmountDisaster.hide();
    underwriterQuickRcd.quickRcdSereniteAmountDisaster.hide();
    underwriterQuickRcd.quickRcdEasyAmountDisaster.hide();
    underwriterQuickRcd.quickRcdPoolAmountDisaster.hide();

    underwriterQuickRcd.changeFormQuick();
    underwriterQuickRcd.changeQuickRcdDisaster();
    underwriterQuickRcd.changeQuickRcdBuildingAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdBatizAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdVsbPibAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdMicPibAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdMbbPibAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdFabricantAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdConstructorAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdStructorAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdSereniteAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdEasyAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdPoolAnnualRevenue();
    underwriterQuickRcd.changeQuickRcdPoolAnnualRevenueRc();
    underwriterQuickRcd.initQuickPoolActivities();
    underwriterQuickRcd.toggleQuickPoolActivities();
});