let actions = {
    franchise: null,
    aggravatingActivitiesCodes: ["2.2", "3.1", "3.2", "5.1"],
    updateFranchise: function (codes, firstLoad) {
        this.setFranchiseSelector();
        if (
            !this.aggravatingActivitiesCodes
            || this.aggravatingActivitiesCodes.length === 0
            || !this.franchise
            || this.franchise.length === 0
            ||  this.franchise.children('option').length !== 3
        ) {
            return;
        }

        let selectedFranchise = this.franchise.val();

        let isAggravating = this.isAggravatingActivities(codes);
        this.showFranchise(isAggravating);
        if (isAggravating) {
            let precedentStatus = this.franchise.children('option[value=1]').attr( "disabled" ) === "disabled";
            this.franchise.children('option[value=1]').attr({'disabled': true, 'selected': false});
            this.franchise.children('option[value=2]').attr({'disabled': true, 'selected': false});
            this.franchise.children('option[value=3]').attr({'disabled': false, 'selected': true});
            if (!precedentStatus && selectedFranchise !== 3 && firstLoad === false) {
                $('#_franchise-aggravating').modal('show');
            }
        } else {
            this.franchise.children('option[value=1]').attr({'disabled': false, 'selected': selectedFranchise === 1});
            this.franchise.children('option[value=2]').attr({'disabled': false, 'selected': selectedFranchise === 2});
            this.franchise.children('option[value=3]').attr({'disabled': false, 'selected': selectedFranchise === 3});
        }
    },
    showFranchise: function (isAggravating) {
        if (isAggravating) {
            this.franchise.children('option[value=3]').text('Franchise de 3 000 €');
        } else {
            this.franchise.children('option[value=3]').text('Franchise de 3 000 € (Minoration prime -5%)');
        }
    },
    isAggravatingActivities: function (codes) {
        let selectedCodes = [];
        $.each(codes, function () {
            if ($(this).prop("checked") === true) {
                selectedCodes.push($(this).attr('data-code'));
            }
        });

        return (this.aggravatingActivitiesCodes.filter(value => selectedCodes.indexOf(value) > -1)).length > 0;
    },
    setFranchiseSelector: function () {
        let franchises = [$('#underwriter_rcde_franchise'), $('#underwriter_rcd_franchise')];
        for (var i = 0, l = franchises.length; i < l; i++) {
            if (franchises[i] && franchises[i].length > 0 && franchises[i].children('option').length === 3) {
                this.franchise =  franchises[i];
                return ;
            }
        }
    },
    checkMaxActivities: function (codes, firstLoad, checkbox = null) {
        let activitiesSelector = $('#underwriter_rcd_activities');
        if (!activitiesSelector || !activitiesSelector.attr('data-max-activities')) {
            return;
        }

        let maxActivities = activitiesSelector.attr('data-max-activities');
        let selectedCodes = [];
        $.each(codes, function () {
            if ($(this).prop("checked") === true) {
                selectedCodes.push($(this).attr('id'));
            }
        });

        if (selectedCodes.length > maxActivities) {
            $('#_activities-max').modal('show');
            if (checkbox != null)
                $(':checkbox', checkbox).prop("checked", !$(':checkbox', checkbox).prop("checked"));
        }
    }
}
export {actions}
