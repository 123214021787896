'use strict';

function _ajaxknppaginator() {
    this.loading = false;
}

_ajaxknppaginator.prototype.getNextPage = function() {
    let node = $(event.currentTarget);

    if (node.hasClass('disabled')) {
        return false;
    } else {
        return {
            'url': node.attr('nextPage')
        };
    }
};

_ajaxknppaginator.prototype.getPathSelector = function($node) {
    let path, node = $node;
    while (node.length) {
        let realNode = node[0], name = realNode.localName;
        if (!name) break;
        name = name.toLowerCase();

        let parent = node.parent();
        let siblings = parent.children(name);

        if (siblings.length > 1) {
            if(siblings.index(realNode ) > 0){
                name += ':nth-child(' + (siblings.index(realNode) + 1) + ')';
            }
        }
        path = name + (path ? '>' + path : '');
        node = parent;
    }

    return path;
};

_ajaxknppaginator.prototype.init = function(options) {
    this.options = options;
    this.pageElem = $(options.paginationSelector).first();
    //todo : select the elementsObject with a better and more generic way
    this.elementsObject = this.pageElem.parent().parent().closest('div.card-body').first();
    this.injectPaginationButton();
};

_ajaxknppaginator.prototype.injectPaginationButton = function() {
    let self = this;
    // let spinner = $('<span>self.options.loadingText</span>');
    let spinner = $('<div class="looping-rhombuses-spinner" style="display:none;"><div class="rhombus"></div><div class="rhombus"></div><div class="rhombus"></div></div>');

    if (self.pageElem.children('li').length > 0 ) {
        self.spinner = spinner.clone();
        self.elementsObject.append(self.spinner);

        self.pageElem.children('li').each(function() {
            if($(this).children().first().is('a')) {
                $(this).attr('nextPage',$(this).find('a').attr('href'));
                $(this).find('a').removeAttr('href').css('cursor','pointer');
                $(this).click(self.clickListener.bind(self));
            }
        });
    }
};

_ajaxknppaginator.prototype.clickListener = function() {
    let self = this;
    let nextPage = this.getNextPage();
    let elementsSelector = this.getPathSelector(this.elementsObject);
    elementsSelector = "section" + /section(.+)/.exec(elementsSelector)[1];

    this.elementsObject.find('table').css('visibility', 'hidden');
    this.spinner.show();

    $.get(nextPage.url, function(data) {
        self.spinner.hide();
        self.elementsObject.find('table').css('visibility', 'visible');

        let dataObj = $(data);
        let elements = $(elementsSelector, dataObj).first();

        if (dataObj.filter(elementsSelector).length) {
            self.elementsObject.replaceWith(dataObj.filter(elementsSelector));
        } else {
            self.elementsObject.replaceWith(elements);
        }
    }).done(function() {
        self.init({
            'loadingText': self.options.loadingText,
            'paginationSelector': self.options.paginationSelector,
        });
    });
};

$('ul.pagination').each(function() {
    var knp = new _ajaxknppaginator();
    knp.init({
        'loadingText': 'Chargement...',
        'paginationSelector': knp.getPathSelector($(this)),
    });
});